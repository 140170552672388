import React, { useEffect, useState } from "react";
import { admin_login as $ } from "strings";
import { useAlert } from "react-alert";
import AlertError from "components/AlertError";
import { Input } from "../../components/Form";
import { registerPassword } from "../../utils/api";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { yupPasswordSchema } from "../../utils/validation";
import { Button, FormErrorMessage } from "./components";
import { saveLoginToken } from "../../utils/localStorageService";
import AuthLayout from "./components/AuthLayout";

const Register = ({ setLogged }) => {
  const [isUpdating, setIsUpdating] = useState(false);
  const alert = useAlert();
  const [userId, setUserId] = useState("");
  const [email, setEmail] = useState("");

  useEffect(() => {
    document.title = "Create Your Password";
    const params = new URLSearchParams(window.location.search);
    setUserId(params.get("userId"));
    setEmail(params.get("email"));
  }, []);

  const initialValues = {
    password: "",
    confirmPassword: ""
  };

  const validationSchema = yup.object().shape({
    password: yupPasswordSchema,
    confirmPassword: yup
      .string()
      .required("This field is required")
      .equals([yup.ref("password")], "Passwords must match")
  });

  return (
    <AuthLayout>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={() => {}}
        enableReinitialize={true}
      >
        {({
          setFieldValue,
          values,
          touched,
          setTouched,
          setFieldTouched,
          errors,
          setFieldError,
          validateForm
        }) => {
          const onChange = async (e) => {
            const { name, value } = e.target;
            await setFieldValue(name, value);
            await setFieldTouched(name, value !== initialValues[name]);
            if (errors[name]) {
              setFieldError(name, null);
            }
          };

          const handleRegisterPassword = async () => {
            if (!email || !userId) {
              alert.error(
                <AlertError
                  error={{ message: "Email and userId are required" }}
                  message={$.auth_error}
                />
              );
              return;
            }
            try {
              const errors = await validateForm();
              if (Object.keys(errors).length) {
                return;
              }
              const response = await registerPassword({
                userId,
                password: values.password
              });
              const talent = response.talent;
              if (!talent) {
                alert.error(<AlertError message="Talent not found." />);
                return;
              }
              saveLoginToken(response);
              setLogged(true);
              if (!response.talent.skills_survey_submitted_at) {
                window.location.href = `/talent/onboard?user=${response.talent.user.id}`;
              } else {
                window.location.href = `/talent/${response.talent.url_suffix}`;
              }
            } catch (e) {
              console.error(e);
              const message = e.response?.data?.error || e.message;
              alert.error(<AlertError message={message} />);
            }
          };

          return (
            <Form className="form h-full">
              <div className="flex h-full flex-col items-center justify-center">
                <h1 className="mb-4 text-2xl font-semibold">
                  Create a password
                </h1>
                <label className="mb-1 w-full text-left text-sm">
                  Enter Password
                </label>
                <div className="mb-2 w-full">
                  <Input
                    type="password"
                    name="password"
                    fontSize="text-sm"
                    value={values.password}
                    onChange={onChange}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        handleRegisterPassword().then();
                      }
                    }}
                  />
                  <FormErrorMessage error={errors.password} />
                </div>
                <label className="mb-1 w-full text-left text-sm">
                  Confirm Password
                </label>
                <div className="mb-2 w-full">
                  <Input
                    type="password"
                    name="confirmPassword"
                    fontSize="text-sm"
                    value={values.confirmPassword}
                    onChange={onChange}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        handleRegisterPassword().then();
                      }
                    }}
                  />
                  <FormErrorMessage error={errors.confirmPassword} />
                </div>
                <Button
                  className="w-full"
                  large
                  disabled={isUpdating}
                  onClick={async () => {
                    setIsUpdating(true);
                    await handleRegisterPassword();
                    setIsUpdating(false);
                  }}
                >
                  Continue
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </AuthLayout>
  );
};

export default Register;
