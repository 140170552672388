import React from "react";

const LoginCredentialsIcon = ({ active }) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.7317 9.00007C15.7317 7.74049 14.7109 6.71973 13.4514 6.71973C11.1163 6.71973 6.88397 6.71973 4.5489 6.71973C3.28932 6.71973 2.26855 7.74049 2.26855 9.00007V15.6338C2.26855 16.8934 3.28932 17.9141 4.5489 17.9141H13.4514C14.7109 17.9141 15.7317 16.8934 15.7317 15.6338V9.00007ZM9.62204 13.04C10.3459 12.7838 10.8659 12.0922 10.8659 11.2804C10.8659 10.2505 10.03 9.41468 9.00012 9.41468C7.97024 9.41468 7.13439 10.2505 7.13439 11.2804C7.13439 12.0922 7.65431 12.7838 8.37821 13.04V14.5973C8.37821 14.9406 8.65683 15.2192 9.00012 15.2192C9.34342 15.2192 9.62204 14.9406 9.62204 14.5973V13.04ZM9.00012 10.6585C9.34342 10.6585 9.62204 10.9371 9.62204 11.2804C9.62204 11.6237 9.34342 11.9023 9.00012 11.9023C8.65683 11.9023 8.37821 11.6237 8.37821 11.2804C8.37821 10.9371 8.65683 10.6585 9.00012 10.6585Z"
        fill={active ? "#3049c5" : "#16325C"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.99992 0.0859375C7.73537 0.0859375 6.52223 0.588442 5.62833 1.48234C4.73444 2.37623 4.23193 3.58937 4.23193 4.85393V7.34157C4.23193 7.68487 4.51055 7.96348 4.85385 7.96348H13.146C13.4893 7.96348 13.7679 7.68487 13.7679 7.34157V4.85393C13.7679 3.58937 13.2654 2.37623 12.3715 1.48234C11.4776 0.588442 10.2645 0.0859375 8.99992 0.0859375ZM8.99992 1.32976C9.93445 1.32976 10.8308 1.70125 11.4917 2.36213C12.1526 3.02302 12.5241 3.9194 12.5241 4.85393V6.71966H5.47576V4.85393C5.47576 3.9194 5.84724 3.02302 6.50813 2.36213C7.16901 1.70125 8.0654 1.32976 8.99992 1.32976Z"
        fill={active ? "#3049c5" : "#16325C"}
      />
    </svg>
  );
};

export default LoginCredentialsIcon;
