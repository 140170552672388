import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { admin_talent_overview as $ } from "strings";
import { toFormat } from "utils/date";
import {
  deleteTalentNoteV2,
  deleteStatusNote,
  patchTalentNoteV2,
  patchStatusNote
} from "utils/adminApi";
import { useAlert } from "react-alert";
import AlertError from "components/AlertError";
import EditNoteForm from "./EditNoteForm";
import { urlify } from "utils/str";
import { B2BLink } from "components/Typography";
import iconFlagged from "../../../../../assets/icon_flagged.svg";

const Note = ({
  note,
  editable,
  updateNote,
  removeNote,
  measureRef,
  className,
  highlightedNote,
  highlightedKeyword
}) => {
  const alert = useAlert();
  const [editing, setEditing] = useState();
  const [highlightedNoteBody, setHighlightedNoteBody] = useState();

  useEffect(() => {
    if (
      !highlightedNote ||
      !highlightedKeyword ||
      highlightedNote.id !== note.id
    )
      return;
    let highlighted = note.note_body.replace(
      highlightedKeyword,
      `<span class="bg-yellow-200">${highlightedKeyword}</span>`
    );
    setHighlightedNoteBody(highlighted);
  }, [highlightedNote]);

  const onDelete = async () => {
    const call = async () => {
      removeNote(note.id);

      try {
        if (note.type === "status_history") {
          await deleteStatusNote(note.id);
        } else {
          await deleteTalentNoteV2(note.talent_ref, note.type, note.id);
        }
      } catch (e) {
        console.error(e);
        alert.error(<AlertError error={e} />);
      }
    };

    await call();
  };

  const onSetFlag = async (newVal) => {
    const call = async () => {
      updateNote(note.id, {
        ...note,
        flag: newVal
      });
      try {
        if (note.type === "status_history") {
          await patchStatusNote(note.id, { flag: newVal });
        } else {
          await patchTalentNoteV2(note.talent_ref, note.type, note.id, {
            flag: newVal
          });
        }
      } catch (e) {
        console.error(e);
        alert.error(<AlertError error={e} />);
      }
    };

    await call();
  };

  return (
    <div id={note.id} ref={measureRef} className={clsx("py-1", className)}>
      {!editing && (
        <div>
          <div className="flex items-center justify-between text-sm font-bold text-midnight">
            <div className="flex-1">
              {note.created_by_name === "Crelate_Populate_Talents_Script"
                ? $.crelate_note_label
                : `${note.created_by_name || note.updated_by_name || $.unknown_author_label}`}
              {note.project_ref && note.project_name ? (
                <>
                  {$.project_added_by_label}
                  <B2BLink to={`/admin/project/${note.project_ref}`}>
                    {note.project_name}
                  </B2BLink>
                  :
                </>
              ) : (
                <>
                  {note.created_by_name !== "Crelate_Populate_Talents_Script" &&
                    " " +
                      (note.flag ? $.flag_added_by_label : $.added_by_label)}
                  {note.flag && (
                    <>
                      &nbsp;
                      <img className="h-5" src={iconFlagged} alt="Flagged" />
                    </>
                  )}
                </>
              )}
            </div>
          </div>
          <div className="rich-content relative min-h-0 whitespace-pre-line text-sm font-normal text-midnight">
            {highlightedNoteBody ? (
              <div
                dangerouslySetInnerHTML={{
                  __html: urlify(highlightedNoteBody)
                }}
              />
            ) : (
              <div
                dangerouslySetInnerHTML={{ __html: urlify(note.note_body) }}
              />
            )}
          </div>
          <div className="flex items-center justify-start text-xs text-kasmir">
            <div className="group relative flex items-center">
              {note.created_timestamp && (
                <div className="">
                  {toFormat(
                    new Date(note.created_timestamp),
                    "MMMM dd, yyyy 'at' h:mm a"
                  )}
                </div>
              )}
              {note.updated_at && (
                <div className="absolute bottom-full z-30 hidden w-content rounded bg-cove px-1 py-1 text-center text-xs font-bold text-white group-hover:inline">
                  {$.last_update_label}
                  <br />
                  {toFormat(
                    new Date(note.updated_at),
                    "MMMM dd, yyyy 'at' h:mm a"
                  )}
                </div>
              )}
              {note.created_timestamp &&
                (editable ? <span className="mx-1">|</span> : "")}
            </div>
            {editable && (
              <>
                <button
                  className="focus:outline-none outline-none p-0 text-xs text-kasmir"
                  onClick={() => {
                    setEditing(true);
                  }}
                >
                  {$.edit_btn}
                </button>
                <span className="mx-1">|</span>
                <button
                  className="focus:outline-none outline-none p-0 text-xs text-kasmir"
                  onClick={onDelete}
                >
                  {$.delete_btn}
                </button>
                <span className="mx-1">|</span>
                {note.flag ? (
                  <button
                    className="focus:outline-none outline-none p-0 text-xs text-kasmir"
                    onClick={() => onSetFlag(false)}
                  >
                    {$.remove_flag_btn}
                  </button>
                ) : (
                  <button
                    className="focus:outline-none outline-none p-0 text-xs text-kasmir"
                    onClick={() => onSetFlag(true)}
                  >
                    {$.flag_btn}
                  </button>
                )}
              </>
            )}
          </div>
        </div>
      )}
      {editing && (
        <div>
          <div className="flex text-sm font-bold text-midnight">
            {note.created_by_name === "Crelate_Populate_Talents_Script"
              ? "Crelate Tags"
              : note.created_by_name || $.no_author_label}{" "}
            {note.flag ? (
              // $.flag_added_by_label :
              <>
                {$.flag_added_by_label}
                <img className="h-5" src={iconFlagged} alt="Flagged" />
              </>
            ) : (
              $.added_by_label
            )}
          </div>
          <EditNoteForm
            note={note}
            updateNote={updateNote}
            setEditing={setEditing}
          />
        </div>
      )}
    </div>
  );
};

export default Note;
