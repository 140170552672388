import React, { useState, useMemo } from "react";
import clsx from "clsx";
import { admin_project_contract_details as $ } from "strings";
import { global as $$ } from "strings";
import {
  patchContractDetails,
  postContractDetails,
  patchProject,
  getCandidates,
  patchCandidate,
  postCandidate,
  deleteContractScope,
  postContractScope,
  getContractDetails
} from "utils/adminApi";
import {
  getFetchContractTalents,
  fetchPaymentDeadlines,
  fetchInvoiceFrequencies,
  fetchCompanies
} from "utils/fetch";
import { useAlert } from "react-alert";
import AlertError from "components/AlertError";
import AlertSuccess from "components/AlertSuccess";
import { useForm, Controller } from "react-hook-form";
import { Label, Error, Input, Textarea, Checkbox } from "components/Form";
import { Button, CloseBtn } from "components/Buttons";
import DatePicker from "components/DatePicker";
import Select from "components/Select";
import { useParams } from "react-router-dom";
import ContractDetailsOptionForm from "./ContractDetailsOptionForm";
import useQualifications from "hooks/useQualifications";
import { Prompt } from "react-router-dom";

const minMaxTypesOptions = [
  { value: null, label: $.select_option },
  { value: "0", label: "Hours" },
  { value: "1", label: "Dollars" }
];

const paymentStyleOptions = [
  { value: null, label: $.select_option },
  { value: "advance", label: "Advance" },
  { value: "arrears", label: "Arrears" }
];

const backgroundCheckOptions = [
  { value: null, label: $.select_option },
  { value: "1", label: "Yes" },
  { value: "0", label: "No" }
];

const clientCategoryOptions = [
  { value: null, label: $.select_option },
  { value: 0, label: "New" },
  { value: 1, label: "Expansion" }
];

const periodTypeOptions = [
  { value: null, label: $.select_option },
  { value: 0, label: "Per Week" },
  { value: 3, label: "Per 2 Weeks" },
  { value: 1, label: "Per Month" },
  { value: 2, label: "Total" }
];

const payPeriodOptions = [
  { value: null, label: $.select_option },
  { value: 4, label: "Per Hour" },
  { value: 0, label: "Per Week" },
  { value: 3, label: "Per 2 Weeks" },
  { value: 1, label: "Per Month" },
  { value: 2, label: "Fixed Fee" }
];

const qbClassOptions = [
  { value: null, label: $.select_option },
  { value: 0, label: "Digital" },
  { value: 1, label: "Offline" },
  { value: 2, label: "Affiliate" },
  { value: 3, label: "Creative" },
  { value: 4, label: "Right Percent" },
  { value: 5, label: "Amazon" },
  { value: 7, label: "Internal Recruiting" },
  { value: 8, label: "FTE" },
  { value: 9, label: "Investor Relations" }
];

const teamOptions = [
  { value: null, label: $.select_option },
  { value: 0, label: "Offline" },
  { value: 1, label: "Amazon" },
  { value: 2, label: "Right Percent" },
  { value: 3, label: "Affiliate" },
  { value: 4, label: "Creative" },
  { value: 5, label: "Other" }
];

const companyStageOptions = [
  { value: null, label: $.select_option },
  { value: 0, label: "Emerging" },
  { value: 1, label: "Early Growth" },
  { value: 2, label: "Late Growth" },
  { value: 3, label: "Mature" }
];

const ContractDetailsForm = ({
  data,
  setData,
  candidate,
  setProjectData,
  projectData
}) => {
  const { id } = useParams();
  const [modal, setModal] = useState();
  const [deadlineKey, setDeadlineKey] = useState("");
  const [frequencyKey, setFrequencyKey] = useState("");
  const alert = useAlert();
  const { qualifications } = useQualifications(true);
  const [saving, setSaving] = useState(false);
  const [type, setType] = useState(
    data.team ? "team" : data.candidate ? "talent" : null
  );

  const fetchTalents = useMemo(() => getFetchContractTalents(id), [id]);

  const {
    handleSubmit,
    register,
    errors,
    watch,
    control,
    setValue,
    formState,
    reset
  } = useForm({
    defaultValues: {
      client_name: data.client_name || "",
      client_sub_rg: data.client_sub_rg || "",
      pay_rate: data.pay_rate || "",
      rate: data.rate || "",
      minimum: data.minimum || "",
      maximum: data.maximum || "",
      invoice_emails: data.invoice_emails || "",
      notes: data.notes || ""
    }
  });

  const inputValues = watch();

  const onSubmit = async ({ scope, maximum, minimum, company, ...values }) => {
    setSaving(true);
    const toSend = {
      ...values,
      project: id,
      rate: values.rate ? values.rate : "",
      qb_class: values.qb_class ? values.qb_class.value : null,
      payment_deadline: values.payment_deadline
        ? values.payment_deadline.value
        : null,
      invoice_frequency: values.invoice_frequency
        ? values.invoice_frequency.value
        : null,
      team: values.team ? values.team.value : null,
      candidate: values.candidate ? values.candidate.value : null,
      payment_style: values.payment_style ? values.payment_style.value : null,
      company_stage: values.company_stage ? values.company_stage.value : null,
      minimum_type: values.minimum_type ? values.minimum_type.value : null,
      minimum_period: values.minimum_period
        ? values.minimum_period.value
        : null,
      maximum_type: values.maximum_type ? values.maximum_type.value : null,
      maximum_period: values.maximum_period
        ? values.maximum_period.value
        : null,
      client_category: values.client_category
        ? values.client_category.value
        : null,
      background_check_required: values.background_check_required
        ? values.background_check_required.value
        : null,
      pay_rate_period: values.pay_rate_period
        ? values.pay_rate_period.value
        : null,
      rate_period: values.rate_period ? values.rate_period.value : null,
      renewal_date: values.renewal_date
        ? values.renewal_date.toISOString().split("T")[0]
        : null
    };

    if (maximum) {
      toSend.maximum = maximum;
    }

    if (minimum) {
      toSend.minimum = minimum;
    }

    const olds = data && Array.isArray(data.scope) ? data.scope : [];
    const news = scope ? scope.map((s) => s.value) : [];
    const oldsKeys = olds.map((s) => s.scope);

    const toDelete = olds.filter((q) => !news.includes(q.scope));

    const toCreate = news.filter((q) => !oldsKeys.includes(q));

    const call = async () => {
      try {
        let response = {};
        if (data.id) {
          for (let q of toDelete) {
            await deleteContractScope(q.id);
          }

          for (let q of toCreate) {
            await postContractScope({ contract: data.id, scope: q });
          }

          response = await patchContractDetails(data.id, toSend);
        } else {
          response = await postContractDetails(toSend);

          for (let q of toCreate) {
            await postContractScope({ contract: response.id, scope: q });
          }

          response = await getContractDetails(projectData.id);
        }
        setData(response);
        reset(values);
        if (values.candidate) {
          candidate.setData(values.candidate.talent);
        }

        if (company && projectData.company_name !== company.label) {
          patchProject(id, { company: company.value });
          setProjectData((d) => ({
            ...d,
            company_name: company.label,
            company: company.company
          }));
        }

        if (values.candidate && values.candidate.value !== data.candidate) {
          const candRes = await getCandidates({
            candidate__user__id: values.candidate.value,
            project__id: id
          });

          if (candRes && Array.isArray(candRes.results) && candRes.results[0]) {
            const cand = candRes.results[0];

            if (cand.status !== "placed") {
              await patchCandidate(cand.id, {
                status: "placed"
              });
            }
          } else {
            await postCandidate({
              status: "placed",
              candidate: values.candidate.value,
              project: id,
              applied: new Date()
            });
          }
        }
        alert.success(<AlertSuccess message={$.success_msg} />, {
          timeout: 2000
        });
        setSaving(false);
      } catch (e) {
        setSaving(false);
        console.error(e);
        alert.error(<AlertError error={e} onRetry={call} />);
      }
    };

    await call();
  };

  return (
    <>
      <form
        className="flex min-h-0 flex-1 flex-col flex-nowrap"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
      >
        <Prompt when={formState.isDirty} message={$$.confirm_close_text} />
        <div className="mt-3 flex min-h-0 flex-1 flex-wrap overflow-y-auto py-4 pl-6">
          <div className="mb-3 mt-2 flex h-20 w-full items-center justify-around space-x-2 pr-8 pt-2 md:w-1/2">
            <button
              onClick={() => setType("talent")}
              type="button"
              className="focus:outline-none group h-10 flex-1"
            >
              <span
                className={clsx(
                  "focus:outline-none inline-flex h-10 w-full items-center justify-center rounded px-4 text-sm font-bold text-link group-focus:ring",
                  type === "talent"
                    ? "bg-link-lighter active:bg-link-lighter"
                    : "bg-link-water hover:bg-link-lighter active:bg-link-light"
                )}
                tabIndex="-1"
              >
                {$.is_talent_button}
              </span>
            </button>
            <button
              onClick={() => setType("team")}
              type="button"
              className="focus:outline-none group h-10 flex-1"
            >
              <span
                className={clsx(
                  "focus:outline-none inline-flex h-10 w-full items-center justify-center rounded px-4 text-sm font-bold text-link group-focus:ring",
                  type === "team"
                    ? "bg-link-lighter active:bg-link-lighter"
                    : "bg-link-water hover:bg-link-lighter active:bg-link-light"
                )}
                tabIndex="-1"
              >
                {$.is_team_button}
              </span>
            </button>
          </div>
          {type === "team" && (
            <div className="mb-3 mt-2 h-20 w-full pr-8 md:w-1/2">
              <Label>{$.team_input}</Label>
              <div className="flex items-center">
                <Controller
                  control={control}
                  className="mr-2 flex-1"
                  name="team"
                  defaultValue={
                    data.team !== null && data.team !== undefined
                      ? teamOptions.find((i) => i.value === data.team)
                      : null
                  }
                  as={<Select />}
                  error={errors.team}
                  placeholder={$.select_option}
                  options={teamOptions}
                />
                {errors.team && <Error>{errors.team.message}</Error>}
                <CloseBtn onClick={() => setType(null)} />
              </div>
            </div>
          )}
          {type === "talent" && (
            <div className="mb-3 mt-2 h-20 w-full pr-8 md:w-1/2">
              <Label>{$.candidate_input}</Label>
              <div className="flex items-center">
                <Controller
                  className="mr-2 flex-1"
                  control={control}
                  name="candidate"
                  defaultValue={
                    data.candidate && candidate
                      ? {
                          talent: candidate.data,
                          value: candidate.data.user.id,
                          label:
                            candidate.data.user.first_name +
                            " " +
                            candidate.data.user.last_name
                        }
                      : null
                  }
                  as={<Select />}
                  error={errors.candidate}
                  placeholder={$.select_option}
                  async
                  loadOptions={fetchTalents}
                />
                {errors.candidate && <Error>{errors.candidate.message}</Error>}
                <CloseBtn onClick={() => setType(null)} />
              </div>
            </div>
          )}
          {!type && <div className="mb-3 mt-2 w-full pr-8 md:w-1/2 lg:h-20" />}
          {projectData && (
            <div className="mb-3 mt-2 w-full pr-8 md:w-1/2">
              <Label>{$.company_input}</Label>
              <Controller
                control={control}
                name="company"
                key={projectData.company?.id}
                defaultValue={
                  projectData.company
                    ? {
                        value: projectData.company?.id,
                        label: projectData.company?.name,
                        company: projectData.company
                      }
                    : null
                }
                as={<Select />}
                error={errors.company}
                placeholder={$.select_option}
                async
                loadOptions={fetchCompanies}
              />
              {errors.company && <Error>{errors.company.message}</Error>}
            </div>
          )}
          <div className="mb-3 mt-2 w-full pr-8 md:w-1/2">
            <Label>{$.client_sub_rg_input}</Label>
            <Input
              name="client_sub_rg"
              maxLength="254"
              ref={register()}
              error={errors.client_sub_rg}
            />
            {errors.client_sub_rg && (
              <Error>{errors.client_sub_rg.message}</Error>
            )}
          </div>
          <div className="mb-3 mt-2 w-full pr-8 md:w-1/2">
            <Label>{$.client_category_input}</Label>
            <Controller
              control={control}
              name="client_category"
              defaultValue={
                data.client_category !== null &&
                data.client_category !== undefined
                  ? clientCategoryOptions.find(
                      (i) => i.value === data.client_category
                    )
                  : null
              }
              as={<Select />}
              error={errors.client_category}
              placeholder={$.select_option}
              options={clientCategoryOptions}
            />
            {errors.client_category && (
              <Error>{errors.client_category.message}</Error>
            )}
          </div>
          <div className="mb-3 mt-2 w-full pr-8 md:w-1/2">
            <Label>{$.company_stage_input}</Label>
            <Controller
              control={control}
              name="company_stage"
              defaultValue={
                data.company_stage !== null && data.company_stage !== undefined
                  ? companyStageOptions.find(
                      (i) => i.value === data.company_stage
                    )
                  : null
              }
              as={<Select />}
              error={errors.company_stage}
              placeholder={$.select_option}
              options={companyStageOptions}
            />
            {errors.company_stage && (
              <Error>{errors.company_stage.message}</Error>
            )}
          </div>
          <div className="mb-3 mt-2 w-full pr-8 md:w-1/3">
            <Label>{$.start_date_input}</Label>
            <Controller
              control={control}
              name="start_date"
              defaultValue={
                data && data.start_date ? new Date(data.start_date) : null
              }
              as={<DatePicker />}
              error={errors.start_date}
              monthly={false}
            />
            {errors.start_date && <Error>{errors.start_date.message}</Error>}
          </div>
          <div className="mb-3 mt-2 w-full pr-8 md:w-1/3">
            <Label>{$.end_date_input}</Label>
            <Controller
              control={control}
              name="end_date"
              defaultValue={
                data?.end_date
                  ? new Date(data.end_date)
                  : projectData?.estimated_end_date
                    ? new Date(projectData.estimated_end_date)
                    : null
              }
              as={<DatePicker />}
              error={errors.end_date}
              monthly={false}
            />
            {errors.end_date && <Error>{errors.end_date.message}</Error>}
          </div>
          <div className="mb-3 mt-2 w-full pr-8 md:w-1/3">
            <Label>{$.renewal_date_input}</Label>
            <Controller
              control={control}
              name="renewal_date"
              defaultValue={
                data && data.renewal_date
                  ? new Date(`${data.renewal_date}T12:00:00Z`)
                  : null
              }
              as={<DatePicker />}
              error={errors.renewal_date}
              monthly={false}
            />
            {errors.renewal_date && (
              <Error>{errors.renewal_date.message}</Error>
            )}
          </div>
          <div className="mb-3 mt-2 w-full pr-8 md:w-1/2">
            <Label>{$.pay_rate_input}</Label>
            <Input
              name="pay_rate"
              type="number"
              step="1"
              min="1"
              max="9999999"
              maxLength="2"
              ref={register({
                pattern: {
                  value: /^[0-9]+$/i,
                  message: $.validation_int
                },
                min: { value: 1, message: $.validation_min },
                max: { value: 9999999, message: $.validation_max }
              })}
              error={errors.pay_rate}
            />
            {errors.pay_rate && <Error>{errors.pay_rate.message}</Error>}
          </div>
          <div className="mb-3 mt-2 w-full pr-8 md:w-1/2">
            <Label>{$.pay_rate_period_input}</Label>
            <Controller
              control={control}
              name="pay_rate_period"
              defaultValue={
                data.pay_rate_period !== null &&
                data.pay_rate_period !== undefined
                  ? payPeriodOptions.find(
                      (i) => i.value === data.pay_rate_period
                    )
                  : null
              }
              as={<Select />}
              error={errors.pay_rate_period}
              placeholder={$.select_option}
              options={payPeriodOptions}
            />
            {errors.pay_rate_period && (
              <Error>{errors.pay_rate_period.message}</Error>
            )}
          </div>
          <div className="mb-3 mt-2 w-full pr-8 md:w-1/3">
            <Label>{$.rate_input}</Label>
            <Input
              disabled={inputValues.percent_spend}
              name="rate"
              type="number"
              step="1"
              min="1"
              max="9999999"
              maxLength="2"
              ref={register({
                pattern: {
                  value: /^[0-9]+$/i,
                  message: $.validation_int
                },
                min: { value: 1, message: $.validation_min },
                max: { value: 9999999, message: $.validation_max }
              })}
              error={errors.rate}
            />
            {errors.rate && <Error>{errors.rate.message}</Error>}
          </div>
          <div className="mb-3 mt-2 w-full pr-8 md:w-1/3">
            <Label>{$.rate_period_input}</Label>
            <Controller
              control={control}
              name="rate_period"
              defaultValue={
                data.rate_period !== null && data.rate_period !== undefined
                  ? payPeriodOptions.find((i) => i.value === data.rate_period)
                  : null
              }
              as={<Select />}
              error={errors.rate_period}
              placeholder={$.select_option}
              options={payPeriodOptions}
              isDisabled={inputValues.percent_spend}
            />
            {errors.rate_period && <Error>{errors.rate_period.message}</Error>}
          </div>
          <div className="mb-3 mt-2 flex w-full items-center pr-8 md:w-1/3 md:pt-3">
            <Controller
              control={control}
              name="percent_spend"
              defaultValue={data ? data.percent_spend : false}
              as={<Checkbox>{$.percent_spend_input}</Checkbox>}
              onClick={() => {
                setValue("rate_period", null);
                setValue("rate", "");
              }}
            />
          </div>
          <div className="mb-3 mt-2 w-full pr-8 md:w-1/3">
            <Label>{$.minimum_input}</Label>
            <Input
              name="minimum"
              type="number"
              step="1"
              min="1"
              max="9999999"
              maxLength="2"
              ref={register({
                pattern: {
                  value: /^[0-9]+$/i,
                  message: $.validation_int
                },
                min: { value: 1, message: $.validation_min },
                max: { value: 9999999, message: $.validation_max }
              })}
              defaultValue={data?.minimum || null}
              error={errors.minimum}
            />
            {errors.minimum && <Error>{errors.minimum.message}</Error>}
          </div>
          <div className="mb-3 mt-2 w-full pr-8 md:w-1/3">
            <Label>{$.minimum_type_input}</Label>
            <Controller
              control={control}
              name="minimum_type"
              defaultValue={
                data.minimum_type !== null && data.minimum_type !== undefined
                  ? {
                      label: data.minimum_type ? "Dollars" : "Hours",
                      value: data.minimum_type
                    }
                  : null
              }
              as={<Select />}
              error={errors.minimum_type}
              placeholder={$.select_option}
              options={minMaxTypesOptions}
            />
            {errors.minimum_type && (
              <Error>{errors.minimum_type.message}</Error>
            )}
          </div>
          <div className="mb-3 mt-2 w-full pr-8 md:w-1/3">
            <Label>{$.minimum_period_input}</Label>
            <Controller
              control={control}
              name="minimum_period"
              defaultValue={
                data.minimum_period !== null &&
                data.minimum_period !== undefined
                  ? periodTypeOptions.find(
                      (i) => i.value === data.minimum_period
                    )
                  : null
              }
              as={<Select />}
              error={errors.minimum_period}
              placeholder={$.select_option}
              options={periodTypeOptions}
            />
            {errors.minimum_period && (
              <Error>{errors.minimum_period.message}</Error>
            )}
          </div>
          <div className="mb-3 mt-2 w-full pr-8 md:w-1/3">
            <Label>{$.maximum_input}</Label>
            <Input
              name="maximum"
              type="number"
              step="1"
              min="1"
              max="9999999"
              maxLength="2"
              ref={register({
                pattern: {
                  value: /^[0-9]+$/i,
                  message: $.validation_int
                },
                min: { value: 1, message: $.validation_min },
                max: { value: 9999999, message: $.validation_max }
              })}
              defaultValue={data?.maximum || null}
              error={errors.maximum}
            />
            {errors.maximum && <Error>{errors.maximum.message}</Error>}
          </div>
          <div className="mb-3 mt-2 w-full pr-8 md:w-1/3">
            <Label>{$.maximum_type_input}</Label>
            <Controller
              control={control}
              name="maximum_type"
              defaultValue={
                data.maximum_type !== null && data.maximum_type !== undefined
                  ? {
                      label: data.maximum_type ? "Dollars" : "Hours",
                      value: data.maximum_type
                    }
                  : null
              }
              as={<Select />}
              error={errors.maximum_type}
              placeholder={$.select_option}
              options={minMaxTypesOptions}
            />
            {errors.maximum_type && (
              <Error>{errors.maximum_type.message}</Error>
            )}
          </div>
          <div className="mb-3 mt-2 w-full pr-8 md:w-1/3">
            <Label>{$.maximum_period_input}</Label>
            <Controller
              control={control}
              name="maximum_period"
              defaultValue={
                data.maximum_period !== null &&
                data.maximum_period !== undefined
                  ? periodTypeOptions.find(
                      (i) => i.value === data.maximum_period
                    )
                  : null
              }
              as={<Select />}
              error={errors.maximum_period}
              placeholder={$.select_option}
              options={periodTypeOptions}
            />
            {errors.maximum_period && (
              <Error>{errors.maximum_period.message}</Error>
            )}
          </div>
          <div className="mb-3 mt-2 w-full pr-8 md:w-1/2">
            <Label>{$.payment_style_input}</Label>
            <Controller
              control={control}
              name="payment_style"
              defaultValue={
                data.payment_style
                  ? paymentStyleOptions.find(
                      (i) => i.value === data.payment_style
                    )
                  : null
              }
              as={<Select />}
              error={errors.payment_style}
              placeholder={$.select_option}
              options={paymentStyleOptions}
            />
            {errors.payment_style && (
              <Error>{errors.payment_style.message}</Error>
            )}
          </div>
          <div className="mb-3 mt-2 w-full pr-8 md:w-1/2">
            <Label>{$.payment_deadline_input}</Label>
            <Controller
              key={"deadline_" + deadlineKey}
              control={control}
              name="payment_deadline"
              defaultValue={
                data.payment_deadline
                  ? {
                      payment_deadline: data.payment_deadline,
                      value: data.payment_deadline.id,
                      label: data.payment_deadline.name
                    }
                  : null
              }
              as={<Select isClearable="true" />}
              error={errors.payment_deadline}
              placeholder={$.select_option}
              async
              cacheOptions={false}
              loadOptions={fetchPaymentDeadlines}
              footerText={$.add_payment_deadline_button}
              footerOnClick={() => {
                setModal("payment_deadline");
              }}
            />
            {errors.payment_deadline && (
              <Error>{errors.payment_deadline.message}</Error>
            )}
          </div>
          <div className="mb-3 mt-2 w-full pr-8 md:w-1/2">
            <Label>{$.invoice_frequency_input}</Label>
            <Controller
              key={"frequency_" + frequencyKey}
              control={control}
              name="invoice_frequency"
              defaultValue={
                data.invoice_frequency
                  ? {
                      invoice_frequency: data.invoice_frequency,
                      value: data.invoice_frequency.id,
                      label: data.invoice_frequency.name
                    }
                  : null
              }
              as={<Select isClearable="true" />}
              error={errors.invoice_frequency}
              placeholder={$.select_option}
              async
              cacheOptions={false}
              loadOptions={fetchInvoiceFrequencies}
              footerText={$.add_invoice_frequency_button}
              footerOnClick={() => {
                setModal("invoice_frequency");
              }}
            />
            {errors.invoice_frequency && (
              <Error>{errors.invoice_frequency.message}</Error>
            )}
          </div>
          <div className="mb-3 mt-2 w-full pr-8 md:w-1/2">
            <Label>{$.qb_class_input}</Label>
            <Controller
              control={control}
              name="qb_class"
              defaultValue={
                data.qb_class !== null && data.qb_class !== undefined
                  ? qbClassOptions.find((i) => i.value === data.qb_class)
                  : null
              }
              as={<Select />}
              error={errors.qb_class}
              placeholder={$.select_option}
              options={qbClassOptions}
            />
            {errors.qb_class && <Error>{errors.qb_class.message}</Error>}
          </div>
          {qualifications.qualificationsByValue && (
            <div className="mb-3 mt-2 w-full pr-8 md:w-1/2">
              <Label>{$.scope_input}</Label>
              <Controller
                control={control}
                name="scope"
                defaultValue={
                  data &&
                  Array.isArray(data.scope) &&
                  qualifications.qualificationsByValue
                    ? data.scope.map((s) => ({
                        scope: s,
                        label:
                          qualifications.qualificationsByValue[s.scope].name,
                        value: s.scope
                      }))
                    : []
                }
                as={<Select />}
                error={errors.scope}
                placeholder={$.select_option}
                isMulti
                options={qualifications.qualificationsOptions}
              />
              {errors.scope && <Error>{errors.scope.message}</Error>}
            </div>
          )}
          <div className="mb-3 mt-2 w-full pr-8 md:w-1/2">
            <Label>{$.background_check_required_input}</Label>
            <Controller
              control={control}
              name="background_check_required"
              defaultValue={
                data &&
                data.background_check_required !== null &&
                data.background_check_required !== undefined
                  ? {
                      label: data.background_check_required ? "Yes" : "No",
                      value: data.background_check_required
                    }
                  : null
              }
              as={<Select />}
              error={errors.background_check_required}
              placeholder={$.select_option}
              options={backgroundCheckOptions}
            />
            {errors.background_check_required && (
              <Error>{errors.background_check_required.message}</Error>
            )}
          </div>
          <div className="mb-3 mt-2 w-full pr-8 md:w-1/2">
            <Label>{$.invoice_emails_input}</Label>
            <Input
              name="invoice_emails"
              ref={register()}
              error={errors.invoice_emails}
            />
            {errors.invoice_emails && (
              <Error>{errors.invoice_emails.message}</Error>
            )}
          </div>
          <div className="mb-3 mt-2 w-full pr-8 md:w-1/2">
            <Label>{$.notes_input}</Label>
            <Textarea name="notes" error={errors.notes} ref={register()} />
            {errors.notes && <Error>{errors.notes.message}</Error>}
          </div>
          <div className="mb-3 mt-2 w-full pr-8 md:w-1/2">
            <Label>{$.project_input}</Label>
            <Input name="project" value={id} disabled />
          </div>
          <div className="mb-3 mt-6 w-full pr-8 text-right">
            <Button type="submit" loading={saving} disabled={saving}>
              {$.save_button}
            </Button>
          </div>
        </div>
      </form>
      {modal && (
        <ContractDetailsOptionForm
          data={data}
          onClose={() => setModal()}
          id={modal}
          onSave={(k, v) => {
            if (k === "payment_deadline") {
              setDeadlineKey(v.value);
            } else if (k === "invoice_frequency") {
              setFrequencyKey(v.value);
            }
            setValue(k, v);
          }}
        />
      )}
    </>
  );
};

export default ContractDetailsForm;
