import React, { useEffect, useCallback } from "react";
import clsx from "clsx";
import { Card } from "./Card";
import UilTimes from "@iconscout/react-unicons/icons/uil-times";
import Section from "components/Section";
import useConfirmClose from "hooks/useConfirmClose";
import { Prompt } from "react-router-dom";

/**
 * Renders a modal close button.
 * @param {callback} onClick - Callback to trigger on click.
 */
const CloseBtn = ({ onClick }) => (
  <button onClick={onClick} className="focus:outline-none group h-8 w-8">
    <span
      className="focus:outline-none inline-flex h-8 w-8 items-center justify-center rounded text-lg text-cove hover:bg-link-water active:bg-geyser group-focus:ring"
      tabIndex="-1"
    >
      <UilTimes size="24" />
    </span>
  </button>
);

/**
 * Renders a modal. It includes some features like confirmation prompt on close and a close button. It is used in all forms.
 * @param {component} children - Children to render inside, like a form with all its inputs.
 * @param {callback} onClose - Callback to trigger on close button click.
 * @param {string} id - Identifier to use with the Section component.
 * @param {boolean} fullHeight - Flag to indicate if the modal should take full height.
 * @param {boolean} isDirty - Flag to enable the confirmation feature.
 */
const Modal = ({ children, onClose, id, fullHeight, isDirty }) => {
  const { canClose, confirmMsg } = useConfirmClose(isDirty);

  const onEscape = useCallback(
    (event) => {
      if (event.keyCode === 27 && canClose()) {
        onClose();
      }
    },
    [canClose, onClose]
  );

  useEffect(() => {
    document.addEventListener("keydown", onEscape);

    return () => {
      document.removeEventListener("keydown", onEscape);
    };
  }, [onEscape]);

  return (
    <div className="fixed left-0 top-0 z-50 flex h-screen w-screen items-center justify-center px-10 py-12">
      <Prompt when={isDirty} message={confirmMsg} />
      <div
        onClick={() => {
          if (canClose()) {
            onClose();
          }
        }}
        className="absolute left-0 top-0 z-40 h-full w-full"
        style={{ backgroundColor: "rgba(31, 34, 39, 0.5)" }}
      />
      <Section
        id={id}
        className={clsx(
          "z-50 mx-auto flex max-h-full w-full lg:w-4/5 xl:w-3/5 2xl:w-2/5",
          fullHeight ? "h-full" : ""
        )}
      >
        <Card className="mb-0 mt-0 flex h-auto max-h-full w-full flex-col flex-nowrap rounded">
          <div className="flex items-center justify-end">
            <CloseBtn
              onClick={() => {
                if (canClose()) {
                  onClose();
                }
              }}
            />
          </div>
          {children}
        </Card>
      </Section>
    </div>
  );
};

export default Modal;
