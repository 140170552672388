import React, { useState } from "react";
import { admin_talent_project as $ } from "strings";
import { Button } from "components/Buttons";
import AddNewProjectModal from "./AddNewProjectModal";

const ProjectListActions = ({ projects, onSuccess }) => {
  const [showAddNewProjectModal, setShowAddNewProjectModal] = useState(false);

  return (
    <div className="candidate_list_actions mb-1 flex items-start py-2">
      <Button
        noMinW
        onClick={() => {
          setShowAddNewProjectModal(true);
        }}
      >
        {$.add_project_title}
      </Button>

      {showAddNewProjectModal && (
        <AddNewProjectModal
          onCancel={() => setShowAddNewProjectModal(false)}
          existingProjectIds={projects.map((p) => p.project_id)}
          onSave={() => {
            setShowAddNewProjectModal(false);
            onSuccess();
          }}
        />
      )}
    </div>
  );
};

export default ProjectListActions;
