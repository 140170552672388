/**
 * Helper for handling rating changes in onboarding qualification screens
 * @param {string} id - The qualification ID being rated
 * @param {number|null} rating - The new rating value
 * @param {Object} values - The current form values
 * @param {string} fieldName - The name of the field in the form values (e.g. 'toolsRatings')
 * @param {Function} setFieldValue - Formik's setFieldValue function
 * @param {Object} errors - The current form errors
 * @param {Function} setFieldError - Formik's setFieldError function
 */

export const handleRatingChange = (
  id,
  rating,
  values,
  fieldName,
  setFieldValue,
  errors,
  setFieldError
) => {
  const currentValues = values[fieldName];
  let newValues;

  if (rating === null || rating === 0) {
    newValues = currentValues;
    delete newValues[id];
  } else {
    newValues = { ...currentValues, [id]: parseInt(rating) };
    if (errors[fieldName]) {
      setFieldError(fieldName, null);
    }
  }
  setFieldValue(fieldName, newValues);
};
