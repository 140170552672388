import React from "react";
import clsx from "clsx";
import { admin_project_candidate_detail as $ } from "strings";
import { toFormat } from "utils/date";
import { statusOptions } from "./constants";
import { urlify } from "utils/str";

const CandidateActivityItem = ({
  note,
  displayStatus,
  measureRef,
  className
}) => {
  const { note_body, status, updated_at, updated_by_name } = note;

  return (
    <div ref={measureRef} className={clsx("py-1", className)}>
      <div>
        <div className="text-sm font-bold text-midnight">
          {status && displayStatus && (
            <span>
              {$.changed_status_label}{" "}
              {statusOptions.find((o) => o.value === status).label}.
            </span>
          )}
        </div>
        <div className="rich-content relative min-h-0 whitespace-pre-line text-sm font-bold text-midnight">
          {note_body && (
            <span className="text-sm font-bold text-midnight">
              {updated_by_name || $.unknown_author_label} {$.added_by_label}:
            </span>
          )}
          {note_body && (
            <div
              className="text-sm font-normal"
              dangerouslySetInnerHTML={{ __html: urlify(note_body) }}
            />
          )}
        </div>
        <div className="flex items-center justify-start text-xs text-kasmir">
          <div className="group relative flex items-center">
            {updated_at && (
              <div className="">
                {toFormat(new Date(updated_at), "MMMM dd, yyyy 'at' h:mm a")}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CandidateActivityItem;
