import React from "react";
import $ from "strings/talent";
import { Input } from "components/Form";
import { CloseXButton } from "../../../components/Buttons";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { FormErrorMessage, Label, SettingsActionBar } from "../components";
import { loginPassword, patchTalentUser } from "../../../utils/api";
import AlertError from "components/AlertError";
import { useAlert } from "react-alert";
import { yupPasswordSchema } from "../../../utils/validation";

const PASSWORD_INPUTS = [
  {
    name: "oldPassword",
    label: $.old_password_label,
    placeholder: $.old_password_placeholder
  },
  {
    name: "newPassword",
    label: $.new_password_label,
    placeholder: $.new_password_placeholder
  },
  {
    name: "confirmPassword",
    label: $.confirm_password_label,
    placeholder: $.confirm_password_placeholder
  }
];

export const ChangePasswordModal = ({ onClose, onUpdate, talentData }) => {
  const alert = useAlert();

  const initialValues = {
    oldPassword: "",
    newPassword: "",
    confirmPassword: ""
  };

  const checkOldPassword = async (oldPassword) => {
    try {
      const email = talentData.user.email;
      if (!email) {
        return alert.error(<AlertError message="Email not found" />);
      }
      await loginPassword({ email, password: oldPassword });
      return true;
    } catch (e) {
      return false;
    }
  };

  const updatePassword = async (values) => {
    try {
      await patchTalentUser(talentData.user.id, {
        password: values.newPassword
      });
      onClose();
      onUpdate();
    } catch (e) {
      alert.error(<AlertError error={e} />);
    }
  };

  const validationSchema = yup.object().shape({
    oldPassword: yup
      .string()
      .required("This field is required")
      .test("checkOldPassword", "Incorrect password", checkOldPassword),
    newPassword: yupPasswordSchema,
    confirmPassword: yup
      .string()
      .required("This field is required")
      .oneOf([yup.ref("newPassword"), null], "Passwords must match")
  });

  return (
    <div
      className="fixed left-0 top-0 z-50 flex h-screen w-screen items-center justify-center bg-black bg-opacity-50"
      onClick={onClose}
    >
      <div
        className="w-85 rounded-xl bg-white"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="flex justify-between border-b px-6">
          <h2 className="my-5 text-2xl font-bold">Change Password</h2>
          <CloseXButton className="my-5" onClick={onClose} />
        </div>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          validateOnChange={false}
          validateOnBlur={false}
          onSubmit={() => {}}
          enableReinitialize={true}
        >
          {({
            setFieldValue,
            values,
            touched,
            setTouched,
            setFieldTouched,
            errors,
            setFieldError,
            validateForm
          }) => {
            const onChange = async (e) => {
              const { name, value } = e.target;
              await setFieldValue(name, value);
              await setFieldTouched(name, value !== initialValues[name]);
              if (errors[name]) {
                setFieldError(name, null);
              }
            };
            return (
              <Form className="form pt-4">
                {PASSWORD_INPUTS.map(({ name, label, placeholder }) => (
                  <div className="px-6 pb-4" key={name}>
                    <div className="flex flex-row">
                      <div className="mr-4 w-full flex-col">
                        <Label>{label}</Label>
                        <Input
                          name={name}
                          type="password"
                          value={values[name]}
                          error={errors[name]}
                          placeholder={placeholder}
                          onChange={onChange}
                        />
                        <FormErrorMessage error={errors[name]} />
                      </div>
                    </div>
                  </div>
                ))}
                <div className="flex border-t px-6 py-3">
                  <SettingsActionBar
                    className="ml-auto"
                    saveDisabled={!Object.values(touched).some(Boolean)}
                    onCancel={onClose}
                    onSave={async () => {
                      const errors = await validateForm();
                      if (!Object.keys(errors).length) {
                        await updatePassword(values);
                      }
                    }}
                  />
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};
