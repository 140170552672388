import React, { useState, useMemo } from "react";
import $ from "strings/talent";
import { Label, Sublabel } from "components/Form";
import { Error } from "../../components/index";
import QualificationsListView from "../../components/QualificationsListView";
import { handleRatingChange } from "../common";

const HIDDEN_INDUSTRY_IDS = [
  "57ddc7ac-da7e-4eee-946c-0b697e0819e8" // International Talent - not user facing
];

const Industries = ({
  setFieldValue,
  values,
  errors,
  setFieldError,
  qualifications
}) => {
  const [open, setOpen] = useState("industries");

  const [industries, companyStages] = useMemo(() => {
    if (!qualifications) return [[], []];

    const industries = qualifications
      .filter(
        (q) =>
          HIDDEN_INDUSTRY_IDS.indexOf(q.id) === -1 &&
          q.type.toLowerCase() === "industry"
      )
      .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));

    const stages = qualifications
      .filter((q) => q.type.toLowerCase() === "company_stage")
      .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));

    return [industries, stages];
  }, [qualifications]);

  const onIndustryRatingChange = (id, rating) => {
    handleRatingChange(
      id,
      rating,
      values,
      "industries",
      setFieldValue,
      errors,
      setFieldError
    );
  };

  const onCompanyStageRatingChange = (id, rating) => {
    handleRatingChange(
      id,
      rating,
      values,
      "companyStages",
      setFieldValue,
      errors,
      setFieldError
    );
  };

  // Convert ratings objects to array format expected by QualificationsListView
  const industryValues = useMemo(() => {
    return Object.entries(values.industries || {}).map(
      ([qualification_id, talent_rating]) => ({
        qualification_id,
        talent_rating
      })
    );
  }, [values.industries]);

  const companyStageValues = useMemo(() => {
    return Object.entries(values.companyStages || {}).map(
      ([qualification_id, talent_rating]) => ({
        qualification_id,
        talent_rating
      })
    );
  }, [values.companyStages]);

  return (
    <div className="grow px-8 py-4">
      <div className="mb-auto py-2">
        <div className="py-2 text-2xl font-bold text-darkest-navy sm:text-3xl">
          {$.industries_title}
        </div>
        <div className="text-base font-normal text-midnight sm:text-lg">
          {$.expertise_subtitle}
        </div>
      </div>
      <div className="flex flex-col">
        <div className="mb-2">
          <Label className="text-sm font-medium text-darkest-navy sm:text-base">
            {$.industries_label}
          </Label>
          <Sublabel>{$.rate_any_sublabel}</Sublabel>
        </div>
        {industries && (
          <QualificationsListView
            title="Industries and Business Models"
            qualifications={industries}
            talentValues={industryValues}
            isOpen={open === "industries"}
            onToggleOpen={() =>
              setOpen(open === "industries" ? "" : "industries")
            }
            onRatingChange={onIndustryRatingChange}
            isUpdating={false}
            showCheckbox={false}
            ratingHelperLabel="Industry"
            showDisplayOnProfile={false}
            theme="white"
          />
        )}
        {companyStages && (
          <QualificationsListView
            title="Company Stages"
            qualifications={companyStages}
            talentValues={companyStageValues}
            isOpen={open === "companyStages"}
            onToggleOpen={() =>
              setOpen(open === "companyStages" ? "" : "companyStages")
            }
            onRatingChange={onCompanyStageRatingChange}
            isUpdating={false}
            showCheckbox={false}
            ratingHelperLabel="Industry"
            showDisplayOnProfile={false}
            theme="white"
          />
        )}
      </div>
      {errors.industries ? (
        <Error msg={errors.industries} />
      ) : (
        <div style={{ height: "22px" }} />
      )}
      {errors.companyStages ? (
        <Error msg={errors.companyStages} />
      ) : (
        <div style={{ height: "22px" }} />
      )}
    </div>
  );
};

export default Industries;
