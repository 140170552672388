import React from "react";
import {impersonateStop} from "../../../utils/localStorageService";
import $ from "strings/talent";

const EndImpersonationButton = ( {...props}) => (
  <button
    className={`h-6 text-base font-bold text-red-darker ${props.className ? props.className : ""}`}
    onClick={e => {
      impersonateStop();
      window.location.href = '/';
    }}
  >{$.end_impersonation_btn}</button>
);

export default EndImpersonationButton;
