import { remove } from "lodash";

export const onCheckboxClick = (
  id,
  values,
  setValues,
  toCreate,
  setToCreate,
  toUpdate,
  setToUpdate
) => {
  const checked = document.getElementById(`checkbox--${id}`)?.checked;
  let newValues = [...values];
  let existingQualification = values.find(
    (tool) => tool.qualification_id === id
  );
  if (!existingQualification) return;
  let newVal = {
    qualification_id: existingQualification.qualification_id,
    qualification_name: existingQualification.qualification_name,
    talent_rating: existingQualification.talent_rating,
    display_on_talent_profile: checked
  };
  const create = toCreate.find((tool) => tool.qualification_id === id);
  if (create) {
    let newToCreate = [...toCreate];
    remove(newToCreate, (tool) => tool.qualification_id === id);
    if (existingQualification.display_on_talent_profile !== checked) {
      newToCreate.push(newVal);
      setToCreate([...newToCreate]);
    }
  } else {
    let newToUpdate = [...toUpdate];
    newVal.talent_qualification_id =
      existingQualification.talent_qualification_id;
    remove(newToUpdate, (tool) => tool.qualification_id === id);
    if (existingQualification.display_on_talent_profile !== checked) {
      newToUpdate.push(newVal);
      setToUpdate([...newToUpdate]);
    }
  }
  remove(newValues, (tool) => tool.qualification_id === id);
  newValues.push(newVal);
  setValues([...newValues]);
};

/**
 * Shared helper for handling rating changes in assessment qualification screens
 * @param {string} id - The qualification ID being rated
 * @param {number|null} rating - The new rating value
 * @param {Array} talentValues - The current talent values array
 * @param {Function} setTalentValues - Function to update talent values
 * @param {Array} toCreate - Array of values to create
 * @param {Function} setToCreate - Function to update toCreate array
 * @param {Array} toUpdate - Array of values to update
 * @param {Function} setToUpdate - Function to update toUpdate array
 */
export const handleAssessmentRatingChange = (
  id,
  rating,
  existingQualifications,
  talentValues,
  setTalentValues,
  toCreate,
  setToCreate,
  toUpdate,
  setToUpdate,
  displayCount
) => {
  let newToUpdate = [...toUpdate];
  let newToCreate = [...toCreate];
  let newTalentValues = [...talentValues];
  let checked = document.getElementById(`checkbox--${id}`)?.checked;
  const existingQualification = existingQualifications.find(
    (q) => q.qualification_id === id
  );

  if (existingQualification) {
    remove(newToUpdate, (skill) => skill.qualification_id === id);
    newToUpdate.push({
      qualification_id: id,
      talent_qualification_id: existingQualification.talent_qualification_id,
      talent_rating: rating,
      display_on_talent_profile: displayCount < 20 ? true : checked
    });
    setToUpdate([...newToUpdate]);
  } else {
    remove(newToCreate, (skill) => skill.qualification_id === id);
    if (rating !== 0) {
      newToCreate.push({
        qualification_id: id,
        talent_rating: rating,
        display_on_talent_profile: displayCount < 20 ? true : checked
      });
      setToCreate([...newToCreate]);
    }
  }
  const removed = remove(
    newTalentValues,
    (skill) => skill.qualification_id === id
  );
  newTalentValues.push({
    ...removed[0],
    qualification_id: id,
    talent_rating: rating,
    display_on_talent_profile: checked
  });
  setTalentValues([...newTalentValues]);
};
