import React, { useState, useEffect, useMemo } from "react";
import toast, { Toaster } from "react-hot-toast";
import $ from "strings/talent";
import {
  IndustriesIcon,
  SpecialtiesIcon,
  SkillsIcon,
  ToolsIcon
} from "pages/talent/onboard/icons";
import Toast from "../components/Toast";
import { Loader } from "../onboard/screens";
import Specialties from "./Specialties";
import Skills from "./Skills";
import Tools from "./Tools";
import Industries from "./Industries";
import {
  getQualifications,
  patchTalentTalent,
  postTalentTalentQualification,
  patchTalentTalentQualification,
  getTalentDataFromUrlSuffix,
  deleteTalentTalentQualification
} from "utils/api";
import { useHistory, useLocation } from "react-router-dom";
import { getUrlSuffix } from "../../../utils/localStorageService";
import Modal from "../components/Modal";
import NavigationPrompt from "react-router-navigation-prompt";
import { SidebarNavSection } from "../components/SidebarNavSection";
import { Label } from "components/Form";

const ASSESSMENTS_SCREENS = {
  specialties: {
    icon: <SpecialtiesIcon />,
    activeIcon: <SpecialtiesIcon active={true} />,
    title: $.specialties_title,
    subtitle: $.specialties_helper
  },
  skills: {
    icon: <SkillsIcon />,
    activeIcon: <SkillsIcon active={true} />,
    title: $.skills_title,
    subtitle: $.skills_helper
  },
  tools: {
    icon: <ToolsIcon />,
    activeIcon: <ToolsIcon active={true} />,
    title: $.tools_title,
    subtitle: $.tools_helper
  },
  industries: {
    icon: <IndustriesIcon />,
    activeIcon: <IndustriesIcon active={true} />,
    title: $.industries_title,
    subtitle: $.industries_helper
  }
};

const AssessmentsPage = ({ talentData, setTalentData }) => {
  const [rsuQualifications, setRsuQualifications] = useState();
  const [activeScreen, setActiveScreen] = useState();
  const [isUpdating, setIsUpdating] = useState(false);
  const [showNavPrompt, setShowNavPrompt] = useState(false);

  const { search } = useLocation();
  const history = useHistory();
  const userSuffix = getUrlSuffix();

  const renderActiveScreen = () => {
    const pageUrl = window.location.href;
    if (pageUrl.includes("tab=skills")) {
      setActiveScreen("skills");
    } else if (pageUrl.includes("tab=specialties")) {
      setActiveScreen("specialties");
    } else if (pageUrl.includes("tab=tools")) {
      setActiveScreen("tools");
    } else if (pageUrl.includes("tab=industries")) {
      setActiveScreen("industries");
    } else {
      setActiveScreen("specialties");
    }
  };

  useEffect(() => {
    renderActiveScreen();
  }, []);

  useEffect(() => {
    renderActiveScreen();
  }, [activeScreen, showNavPrompt, search]);

  const talentQualifications = useMemo(() => {
    if (!talentData || !talentData.qualifications) return;
    const { qualifications } = talentData;
    let skills;
    let tools;
    let industries;
    let companyStages;

    industries = qualifications
      .filter((q) => q.qualification?.type?.toLowerCase() === "industry")
      .map((industry) => ({
        qualification_id: industry.qualification.id,
        qualification_name: industry.qualification.name,
        talent_qualification_id: industry.id,
        talent_rating: industry.talent_rating,
        display_on_talent_profile: industry.display_on_talent_profile
      }));
    skills = qualifications
      .filter((q) => q.qualification?.type?.toLowerCase() === "skill")
      .map((skill) => ({
        qualification_id: skill.qualification.id,
        qualification_name: skill.qualification.name,
        talent_qualification_id: skill.id,
        talent_rating: skill.talent_rating,
        display_on_talent_profile: skill.display_on_talent_profile
      }));
    tools = qualifications
      .filter((q) => q.qualification?.type?.toLowerCase() === "tool")
      .map((tool) => ({
        qualification_id: tool.qualification.id,
        qualification_name: tool.qualification.name,
        talent_qualification_id: tool.id,
        talent_rating: tool.talent_rating,
        display_on_talent_profile: tool.display_on_talent_profile
      }));
    companyStages = qualifications
      .filter((q) => q.qualification?.type?.toLowerCase() === "company_stage")
      .map((companyStage) => ({
        qualification_id: companyStage.qualification.id,
        qualification_name: companyStage.qualification.name,
        talent_qualification_id: companyStage.id,
        talent_rating: companyStage.talent_rating,
        display_on_talent_profile: companyStage.display_on_talent_profile
      }));
    return { skills, tools, industries, companyStages };
  }, [talentData]);

  const fetchQualifications = async () => {
    try {
      const qualificationsResponse = await getQualifications({
        page_size: 9999
      });
      setRsuQualifications(qualificationsResponse.results);
    } catch (e) {
      console.error("Failed to fetch qualifications");
    }
  };

  const onNavClick = (screen) => {
    if (!showNavPrompt) {
      setActiveScreen(screen);
    }
    history.push(`/talent/${userSuffix}/expertise?tab=${screen}`);
  };

  useEffect(() => {
    fetchQualifications();
  }, []);

  const onUpdateTalent = async (toSend) => {
    const userId = talentData?.user?.id;
    if (!userId) return;
    setIsUpdating(true);
    const originalValues = { ...talentData };
    try {
      const response = await patchTalentTalent(userId, toSend);
      setTalentData({ ...talentData, ...toSend });
      setIsUpdating(false);
      toast(<Toast message="Specialties saved successfully" />);
      return response;
    } catch (e) {
      setTalentData({ ...originalValues });
      setIsUpdating(false);
      console.error({ e });
    }
  };

  const onUpdateQualifications = async (toCreate, toUpdate) => {
    setIsUpdating(true);
    const originalValues = [...talentData.qualifications];
    try {
      toCreate.forEach((q) => {
        const rsuQualification = rsuQualifications.find(
          (qual) => qual.id === q.qualification_id
        );
        if (!rsuQualification) return;
        const toSend = {
          qualification_id: rsuQualification.id,
          talent_rating: q.talent_rating,
          display_on_talent_profile: q.display_on_talent_profile
        };
        postTalentTalentQualification(toSend);
      });
      const toDelete = toUpdate.filter((q) => q.talent_rating === 0);
      toDelete.forEach((q) =>
        deleteTalentTalentQualification(q.talent_qualification_id)
      );
      const patchPromises = toUpdate
        .filter((q) => q.talent_rating > 0)
        .map((q) =>
          patchTalentTalentQualification(q.talent_qualification_id, {
            talent_rating: q.talent_rating,
            display_on_talent_profile: q.display_on_talent_profile
          })
        );
      await Promise.all(patchPromises);
      setIsUpdating(false);
      toast(<Toast message="Qualifications saved successfully" />, {
        timeout: 2000
      });
      await getTalentDataFromUrlSuffix(talentData.url_suffix).then(
        setTalentData
      );
      return true;
    } catch (e) {
      setTalentData({ ...talentData, qualifications: [...originalValues] });
      setIsUpdating(false);
      console.error({ e });
    }
  };

  return (
    <div className="h-full w-full">
      {talentData && rsuQualifications ? (
        <div className="flex flex-col gap-4 p-4 md:flex-row">
          <div className="md:w-1/3">
            <SidebarNavSection
              title={$.expertise_navlink}
              screensMap={ASSESSMENTS_SCREENS}
              activeScreen={activeScreen}
              onClick={onNavClick}
            />
          </div>
          <div className="grow gap-5 rounded-xl bg-white p-3.5 sm:p-4 md:w-2/3 md:px-6 md:py-4">
            <div className="pb-2 text-2xl font-bold text-darkest-navy">
              {ASSESSMENTS_SCREENS[activeScreen].title}
            </div>
            <div className="pb-3">
              <Label className="text-sm font-normal text-darkest-navy sm:text-base">
                {ASSESSMENTS_SCREENS[activeScreen].subtitle}
              </Label>
            </div>
            {{
              specialties: (
                <Specialties
                  talentData={talentData}
                  setTalentData={setTalentData}
                  onUpdate={onUpdateTalent}
                  isUpdating={isUpdating}
                  setShowNavPrompt={setShowNavPrompt}
                />
              ),
              skills: (
                <Skills
                  rsuQualifications={rsuQualifications}
                  talentSkills={talentQualifications?.skills}
                  talentData={talentData}
                  onUpdate={onUpdateQualifications}
                  isUpdating={isUpdating}
                  setShowNavPrompt={setShowNavPrompt}
                />
              ),
              tools: (
                <Tools
                  rsuQualifications={rsuQualifications}
                  talentTools={talentQualifications?.tools}
                  talentData={talentData}
                  onUpdate={onUpdateQualifications}
                  isUpdating={isUpdating}
                  setShowNavPrompt={setShowNavPrompt}
                />
              ),
              industries: (
                <Industries
                  rsuQualifications={rsuQualifications}
                  talentIndustries={talentQualifications?.industries}
                  talentCompanyStages={talentQualifications?.companyStages}
                  talentData={talentData}
                  onUpdate={onUpdateQualifications}
                  isUpdating={isUpdating}
                  setShowNavPrompt={setShowNavPrompt}
                />
              )
            }[activeScreen] || <div />}
          </div>
        </div>
      ) : (
        <Loader
          msg={$.assessments_loading_msg}
          subtitle={$.assessments_loading_subtitle}
        />
      )}
      <Toaster
        toastOptions={{
          style: {
            border: "1px solid ##e5f2ea",
            borderRadius: "5px",
            backgroundColor: "#e5f2ea"
          }
        }}
      />
      <NavigationPrompt
        when={(currentLocation, nextLocation) => {
          const promptIf =
            showNavPrompt &&
            (currentLocation.pathname !== nextLocation.pathname ||
              currentLocation.search !== nextLocation.search);
          return promptIf;
        }}
      >
        {({ onConfirm, onCancel }) => (
          <Modal
            when={true}
            title="Discard Changes"
            onClose={onCancel}
            onClickCancel={onCancel}
            onClickSave={() => {
              onConfirm();
              setShowNavPrompt(false);
            }}
            saveBtnText="Discard Changes"
            showDelete={false}
            id="discard_changes_form--assessments"
          >
            <div className="flex flex-col gap-4 p-2">
              <div className="text-base">
                {$.discard_changes_expertise_message_1}
              </div>
              <div className="text-base">{$.discard_changes_message_2}</div>
            </div>
          </Modal>
        )}
      </NavigationPrompt>
    </div>
  );
};

export default AssessmentsPage;
