import React from "react";

import clsx from "clsx";

/**
 * Renders label to be used with a form field.
 * @param {component} children - The children to render, in most cases a clear button.
 * @param {string} className - Classes to append to the label default classes.
 */
const Label = ({ children, className = "" }) => (
  <label
    className={clsx(
      "mb-1 flex items-center text-sm font-bold text-midnight sm:text-base",
      className
    )}
  >
    {children}
  </label>
);

export default Label;
