import React, { useState } from "react";
import clsx from "clsx";
import { admin_project_candidate_detail as $ } from "strings";
import RichTextEditor from "components/RichTextEditorNew";
import UilArrow from "@iconscout/react-unicons/icons/uil-arrow-circle-right";
import { isBodyEmpty } from "components/RichTextEditor";
import AlertError from "components/AlertError";
import { useAlert } from "react-alert";
import { serialize } from "utils/htmlSerializer";
import { patchCandidateV2, getCandidateProjectNotes } from "utils/adminApi";

const CandidateActivityForm = ({ projectId, candidate, onAddNote }) => {
  const alert = useAlert();
  const [saving, setSaving] = useState();
  const [values, setValues] = useState({
    description: [{ type: "paragraph", children: [{ text: "" }] }]
  });

  const updateVal = (k, v) => {
    setValues((vs) => ({ ...vs, [k]: v }));
  };

  const isEmpty = isBodyEmpty(values.description);

  const onSubmit = async () => {
    setSaving(true);

    let description = values.description;

    const toSend = {
      description: serialize({ children: description }),
      project: projectId,
      status: candidate.status
    };

    const call = async () => {
      try {
        await patchCandidateV2(candidate.id, toSend);
        setValues({
          description: [{ type: "paragraph", children: [{ text: "" }] }]
        });
        const updatedNotes = await getCandidateProjectNotes({
          candidate_id: candidate.candidate_user_id,
          type: "status_history",
          project_id: projectId,
          ordering: "-created_timestamp",
          page_size: 1
        });
        if (!updatedNotes?.results?.length) return;
        const newNote = updatedNotes.results[0];
        onAddNote(newNote);
      } catch (e) {
        console.error(e);
        alert.error(<AlertError error={e} onRetry={call} />);
      }

      setSaving(false);
    };

    await call();
  };

  return (
    <>
      <RichTextEditor
        placeholder={$.add_candidate_note}
        value={values.description}
        onChange={(v) => updateVal("description", v)}
      />
      <div className="flex items-center justify-start rounded-b border-b border-l border-r border-geyser px-2 pb-1">
        <div className="flex flex-1 items-center justify-end">
          {!isEmpty && (
            <button
              onClick={() => {
                updateVal("description", [
                  { type: "paragraph", children: [{ text: "" }] }
                ]);
              }}
              type="button"
              className="flex items-center text-sm font-bold text-kasmir"
            >
              {$.cancel_btn}
            </button>
          )}
          <button
            onClick={onSubmit}
            type="button"
            className={clsx(
              "ml-4 flex items-center text-sm font-bold",
              saving || isEmpty
                ? "cursor-not-allowed text-caded"
                : "text-kasmir"
            )}
            disabled={saving || isEmpty}
          >
            {saving ? $.saving_btn : $.submit_btn}
            <UilArrow size="20" className="ml-1" />
          </button>
        </div>
      </div>
    </>
  );
};

export default CandidateActivityForm;
