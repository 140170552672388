import React, { useMemo } from "react";
import $ from "strings/talent";
import {
  FullTimeIcon,
  TimezoneIcon,
  TravelDistanceIcon,
  TravelFlexibilityIcon
} from "pages/talent/onboard/icons";
import { EditBtn } from "components/Buttons";

const FULLTIME_INTEREST_OPTIONS = [
  {label: $.yes_option, value: true},
  {label: $.no_option, value: false}
]

const TRAVEL_FLEXIBILITY_OPTIONS = [
  {label: $.regular_days_option, value: 'regular'},
  {label: $.occasional_meetings_option, value: 'occasional'},
  {label: $.special_events_option, value: 'special'},
  {label: $.not_interested_travel_option, value: 'not_interested' }
]

const TRAVEL_DISTANCE_OPTIONS = [
  {label: $.local_travel_option, value: 'local'},
  {label: $.domestic_travel_option, value: 'domestic'},
  {label: $.international_travel_option, value: 'international'}
]

const WORKING_TIMEZONES_OPTIONS = [
  {label: $.est_option, value: 'eastern'},
  {label: $.cst_option, value: 'central'},
  {label: $.mst_option, value: 'mountain'},
  {label: $.pst_option, value: 'pacific'}
]

const ENGAGEMENT_FIELDS = [
  {
    icon: <TravelFlexibilityIcon />,
    title: 'Travel Flexibility',
    field: 'onsite_frequency',
    isPipeSeparated: true,
    options: TRAVEL_FLEXIBILITY_OPTIONS
  },
  {
    icon: <TravelDistanceIcon />,
    title: 'Travel Distance',
    field: 'travel_distance_preferences',
    isPipeSeparated: true,
    options: TRAVEL_DISTANCE_OPTIONS
  },
  {
    icon: <TimezoneIcon />,
    title: 'Working Timezones',
    field: 'working_timezones',
    isPipeSeparated: true,
    options: WORKING_TIMEZONES_OPTIONS
  },
  {
    icon: <FullTimeIcon />,
    title: 'Open to Full-Time Hire',
    field: 'fulltime_interest',
    isPipeSeparated: false,
    options: FULLTIME_INTEREST_OPTIONS
  }
]

const EngagementFlexibility = ({ talentData, isPublicProfile }) => {

  const isEmpty = useMemo(() => {
    if (!talentData) return;
    if (
      !talentData.onsite_frequency?.length &&
      !talentData.travel_distance_preferences?.length &&
      !talentData.working_timezones?.length &&
      !talentData.fulltime_interest === null
    ) {
      return true;
    } else {
      return false;
    }
  }, [talentData]);

  const getPipeSeparatedValues = (values, options) => {
    let valuesToDisplay = '';
    if (!values) {
      valuesToDisplay = 'Not Set';
    } else {
      const valuesArr = values.split("|");
      valuesArr.forEach((v, i) => {
        let label = options.find(o => o.value === v)?.label;
        valuesToDisplay += label;
        if (i !== valuesArr.length - 1) {
          valuesToDisplay += ", "
        }
      });
    }
    return valuesToDisplay;
  }

  return (
    <>
    { (isPublicProfile && isEmpty) ? <></> :
    <div className="bg-white rounded-extra-large p-4 flex flex-col gap-y-3">
      <div className="flex items-center">
        <div className="text-lg font-bold">Engagement Flexibility</div>
        {!isPublicProfile && <div className="ml-auto mr-0">
          <EditBtn />
        </div>}
      </div>
      {ENGAGEMENT_FIELDS.map((f, i) =>
        <div className="flex items-center gap-x-2.5" key={i}>
          <div style={{minWidth: '34px'}} className="h-8.5 w-8.5 min-w-8.5 flex bg-lightest-grey rounded-md">
            <div className="m-auto">{f.icon}</div>
          </div>
          <div className="flex flex-col gap-y-0.5">
            <div className="text-xs text-kasmir">{f.title}</div>
            <div className="text-sm text-midnight text-balance">
              {f.isPipeSeparated ?
                getPipeSeparatedValues(talentData[f.field], f.options) :
                f.options.find(o => o.value === talentData[f.field])?.label || 'Not Set'
              }
              </div>
          </div>
        </div>
      )}
    </div>
    }
    </>
  )
};

export default EngagementFlexibility;
