import React from "react";
import clsx from "clsx";
import ReactSelect, { components } from "react-select";
import { B2B } from "./Typography";
import UilTimes from "@iconscout/react-unicons/icons/uil-times";
import { AsyncPaginate, wrapMenuList } from "react-select-async-paginate";

const baseStyles = {
  option: (provided, state) => ({
    ...provided,
    padding: "0",
    backgroundColor: state.isSelected
      ? "#dce9f6"
      : state.isFocused
        ? "#f4f6f9"
        : "#fff",
    fontFamily: "Lato",
    fontWeight: "400",
    fontSize: ".75rem",
    color: state.isDisabled
      ? "#a8b7c7"
      : state.isSelected
        ? "#0070d2"
        : "#54698d",
    "&:hover": {
      backgroundColor: state.isSelected ? "#dce9f6" : "#f4f6f9"
    }
  }),
  multiValue: (provided) => ({
    ...provided,
    backgroundColor: "#fff",
    borderRadius: ".25rem",
    height: "1.5rem",
    display: "flex",
    alignItems: "center",
    fontWeight: "700",
    fontSize: ".875rem",
    color: "#16325c"
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    padding: "0",
    paddingLeft: "0",
    fontWeight: "700",
    fontSize: ".875rem",
    color: "#16325c"
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    color: "#16325c",
    padding: "0",
    "&:hover": {
      backgroundColor: "transparent",
      color: "#16325c"
    }
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: "#16325c",
    "&:hover": {
      color: "#16325c"
    }
  }),
  clearIndicator: (provided) => ({
    ...provided,
    color: "#16325c",
    padding: "0",
    "&:hover": {
      color: "#16325c"
    }
  }),
  menuList: (provided) => ({
    ...provided,
    padding: "0"
  }),
  menu: (provided) => ({
    ...provided,
    boxShadow: "0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)",
    marginTop: "0",
    zIndex: "40"
  }),
  noOptionsMessage: (provided) => ({
    ...provided,
    fontWeight: "400",
    fontSize: ".75rem",
    color: "#54698d"
  }),
  valueContainer: (provided) => ({
    ...provided,
    fontWeight: "700",
    fontSize: ".875rem",
    height: "1.25rem",
    color: "#16325c",
    padding: "0"
  }),
  indicatorContainer: (provided) => ({
    ...provided,
    // padding 0 except for right (8px)
    padding: "0 8px 0 0"
  }),
  singleValue: (provided, state) => ({
    ...provided,
    fontWeight: "700",
    height: "1.25rem",
    fontSize: ".875rem",
    color: state.selectProps.secondary ? "#0070d2" : "16325c"
  }),
  input: (provided) => ({
    ...provided,
    fontWeight: "700",
    fontSize: ".875rem",
    height: "1.25rem",
    color: "#16325c",
    margin: 0,
    padding: 0
  }),
  placeholder: (provided) => ({
    ...provided,
    fontWeight: "400",
    color: "#54698d",
    fontSize: ".875rem"
  }),
  menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
  indicatorSeparator: () => ({ display: "none" }),
  control: (provided, state) => ({
    ...provided,
    backgroundColor: state.selectProps.secondary ? "#f4f6f9" : "inherit",
    minHeight: "1.25rem",
    height: "1.25rem",
    width: "100%",
    marginTop: "0",
    marginBottom: "0",
    borderRadius: ".25rem",
    padding: "0",
    display: "flex",
    alignItems: "center",
    boxShadow: "0",
    border: state.selectProps.error
      ? "solid 1.5px #a10000"
      : state.isFocused
        ? "solid 1.5px #0070d2"
        : state.selectProps.secondary
          ? "0"
          : "solid 1px #fff"
  })
};

const ClearIndicator = (props) => null;

const MultiValueRemove = (props) => (
  <components.MultiValueRemove {...props}>
    <UilTimes size="13" className="hidden group-hover:block" />
    <span className="multiselect-comma">,</span>
  </components.MultiValueRemove>
);

const MenuList = ({ children, selectProps, ...rest }) => {
  return (
    <components.MenuList {...rest}>
      {children}
      {selectProps.footerText && (
        <div className="sticky bottom-0 border-t border-link-water bg-white px-0 py-1">
          <B2B className="cursor-pointer" onClick={selectProps.footerOnClick}>
            <span className="text-kasmir">{selectProps.footerText}</span>
          </B2B>
        </div>
      )}
    </components.MenuList>
  );
};

const PaginateMenuList = wrapMenuList(MenuList);

const Select = ({
  async,
  onChange,
  components = {},
  customStyles = {},
  ...props
}) => {
  const BaseSelect = async ? AsyncPaginate : ReactSelect;

  return (
    <BaseSelect
      debounceTimeout={250}
      cacheOptions={async}
      defaultOptions={async}
      menuPortalTarget={document.body}
      menuPosition="fixed"
      menuPlacement="auto"
      {...props}
      className={clsx(props.className, "group")}
      onChange={(v) => {
        let value = v;
        if (props.isMulti && v && v.length) {
          if (v.find((o) => o.value === "anytime")) {
            value = props.options;
          }
        } else if (props.isMulti) {
          value = "";
        } else if (!props.isMulti && v && v.value === null) {
          value = null;
        }

        onChange(value);
      }}
      additional={{ page: 1 }}
      styles={{ ...baseStyles, ...customStyles }}
      components={{
        ...{
          DropdownIndicator: components.DropdownIndicator || (() => null),
          ClearIndicator,
          MultiValueRemove,
          MenuList: async ? PaginateMenuList : MenuList
        },
        ...components
      }}
      isOptionDisabled={(option) => option.disabled}
    />
  );
};

export default Select;
