import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { usePageCache } from "contexts/pageCacheContext";
import { useParams } from "react-router-dom";
import { admin_talent_attachments as $ } from "strings";
import Section from "components/Section";
import LoadPlaceholder from "components/LoadPlaceholder";
import { getAdminUser } from "utils/localStorageService";
import { LinkPreview } from "../../../../talent/Profile/LinkPreview";
import { QualificationsDisplay } from "components/QualificationsDisplay";
import { orderBy } from 'lodash';
import companyLogoPlaceholder from "../../../../../assets/company_logo_placeholder.png";

const Experiences = ({ data }) => {
  const { id } = useParams();
  const { set } = usePageCache();
  const [talentExperiences, setTalentExperiences] = useState();

  useEffect(() => {
    if (!data) return;
    populateTalentExperiences(data.results);
  }, [data]);

  useEffect(() => {
    set("url_talent", `/admin/talent/${id}/experiences`);
  }, [set, id]);

  const populateTalentExperiences = (experiences) => {
    const existingProjectIds = {};
    const projects = {};


    const orderedExperiences = orderBy(
      experiences,
      [
        // Projects with no date info last
        (project) => (project.end_date || project.start_date ? 0 : 1),
        // ongoing experiences first
        (project) => (project.end_date ? 1 : 0),
        // then by end date, then by start date - if no end date
        (project) => project.end_date || project.start_date
      ],
      ["asc", "asc", "desc"]
    );
    for (const project of orderedExperiences) {
      existingProjectIds[project.project_id] = true;
      const key = project.company?.name || 'Company unknown';
      if (!projects[key]) {
        projects[key] = [];
      }
      projects[key].push(project);
    }
    setTalentExperiences(projects);
  }

  const getProjectTime = project => {
    const simpleDate = date => {
      const d = new Date(date);
      return d.toLocaleString('default', { month: 'short' }) + ' ' + d.getFullYear();
    };

    if (project.start_date && project.end_date) {
      let months = Math.round((new Date(project.end_date) - new Date(project.start_date)) / (1000 * 60 * 60 * 24 * 30));
      let years = Math.floor(months / 12);
      let time;
      if (years > 0) {
        months = months % 12;
        time = `${years} yr${years > 1 ? 's' : ''}`;
        if (months > 0) {
          time += ` ${months} mo${months > 1 ? 's' : ''}`;
        }
      } else if (months > 0) {
        time = `${months} mo${months > 1 ? 's' : ''}`;
      } else {
        time = 'Less than a month';
      }
      return `${simpleDate(project.start_date)} - ${simpleDate(project.end_date)} · ${time}`;
    } else if (project.start_date && !project.end_date) {
      return `Since ${simpleDate(project.start_date)}`;
    } else if (!project.start_date && project.end_date) {
      return `Until ${simpleDate(project.end_date)}`;
    } else {
      return 'No information about project time';
    }
  };

  const getProjectInfo = project => {
    let size = project.employees;
    if (size && !size.includes(' employees')) {
      size += ' employees';
    }
    if (project.commitment && size) {
      return `${project.commitment} · ${size}`;
    } else if (project.commitment && !size) {
      return project.commitment;
    } else if (!project.commitment && size) {
      return size;
    } else {
      return 'No information';
    }
  };

  if (!data || !talentExperiences) {
    return (
      <div className="bg-white flex-1 flex flex-col px-2 py-4">
        <LoadPlaceholder className="w-60 h-4 mt-10 mb-10" />
        <LoadPlaceholder className="w-60 h-4 mb-2" />
        <LoadPlaceholder className="w-40 h-4 mb-2" />
        <LoadPlaceholder className="w-32 h-4 mb-2" />
      </div>
    );
  }

  return (
    <Section id="admin_talent_experiences" className="flex mt-1 h-full flex-col w-full bg-white overflow-y-auto">
      <div className="flex flex-col gap-2.5 text-midnight">
        {talentExperiences && Object.entries(talentExperiences).map(([companyName, projects], i) => (
          <div key={i} className="flex flex-col bg-lightest-grey rounded-lg p-3.5">
            <div className="mb-2.5 h-15 flex flex-row gap-x-2.5">
              <img
                  className="w-15 h-15 rounded-lg"
                  src={projects[0]?.company?.logo || companyLogoPlaceholder}
                  alt="logo"
                />
              <div className="my-auto flex-auto">
                <div className="text-base font-bold">{companyName}</div>
                <div className="text-sm text-kasmir">{projects.length} project{projects.length > 1 ? 's' : ''}</div>
              </div>
            </div>
            {projects.map((project, i) => (
            <div className="bg-white rounded-lg mb-2.5 ml-16 p-4 flex flex-col gap-3" key={i}>
              <div className="flex flex-row">
                <div className={clsx(project.is_hidden ? 'opacity-50' : '')}>
                  <div className="flex flex-row gap-x-2.5 mr-auto">
                    <div className="text-base font-bold">{project.name}</div>
                  </div>
                  <div className="text-sm text-kasmir">{getProjectTime(project)}</div>
                  <div className="text-sm text-kasmir">{getProjectInfo(project)}</div>
                </div>
              </div>
              <div className={clsx("text-kasmir text-sm", project.is_hidden ? 'opacity-50' : '')}>
                {project.results || 'No description'}
              </div>
              {(project.links || project.attached_files) && (<div className={clsx("flex flex-col gap-y-2.5", project.is_hidden ? 'opacity-50' : '')}>
                {project.links.map((link, i) => (
                  <LinkPreview className="mr-auto" name={link.name} key={i} />
                ))}
                {project.attached_files.map((file, i) => (
                  <LinkPreview className="mr-auto" name={file.filename} key={i} />
                ))}
              </div>)}
              {!!project.skills_and_tools && (<div className={clsx(project.is_hidden ? 'opacity-50' : '')}>
                <div className="text-sm font-bold mb-2">{$.skills_and_tools_subtitle}</div>
                {project.skills_and_tools && <QualificationsDisplay qualifications={project.skills_and_tools} />}
              </div>)}
            </div>
            ))}
          </div>
        ))}
      </div>
    </Section>
  )
}

export default Experiences;
