import React, { useState } from "react";
import clsx from "clsx";
import { B2DB, B1 } from "./Typography";
import UilCopy from "@iconscout/react-unicons/icons/uil-copy";
import { global as $ } from "strings";

/**
 * Renders an information box. Used in some talent and project pages.
 * @param {component} children - The children to render, the information to show.
 * @param {string} title - Header text of the information.
 * @param {component} Icon - Icon component to render.
 * @param {string} className - Classes to append to the box default classes.
 */
const InfoRow = ({
  children,
  title,
  Icon,
  className,
  contentClass = "",
  clipboard
}) => {
  const [hasClip, setHasClip] = useState(false);

  const handleClick = () => {
    if (!clipboard) {
      return;
    }

    navigator.clipboard.writeText(clipboard);
    setHasClip(true);
    setTimeout(() => {
      setHasClip(false);
    }, 1000);
  };

  return (
    <div
      className={clsx(
        "group my-4 flex w-full max-w-full items-center rounded border border-link-water bg-white px-2 py-4",
        className
      )}
      onClick={handleClick}
    >
      <B2DB className="mr-4 flex items-center">
        <Icon size="18" className="mr-2 flex-shrink-0" />
        {title}
      </B2DB>
      <B1 className={contentClass}>{children}</B1>
      {!!clipboard && !hasClip && (
        <div
          className="hidden text-kasmir opacity-80 group-hover:block"
          title={$.copy_clipboard}
        >
          <UilCopy size="20" />
        </div>
      )}
      {hasClip && (
        <div className="text-xs font-medium text-kasmir">
          {$.copied_clipboard}
        </div>
      )}
    </div>
  );
};

export default InfoRow;
