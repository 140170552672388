import React, { useState, useMemo, useEffect } from "react";
import $ from "strings/talent";
import ActionBar from "./ActionBar";
import { onCheckboxClick, handleAssessmentRatingChange } from "./common";
import QualificationsListView, {
  sortQualifications
} from "../components/QualificationsListView";

const Skills = ({
  rsuQualifications,
  talentSkills,
  talentData,
  onUpdate,
  isUpdating,
  setShowNavPrompt
}) => {
  const [open, setOpen] = useState("specialty");
  const [displayActionBar, setDisplayActionBar] = useState(false);
  const [talentValues, setTalentValues] = useState([...talentSkills]);
  const [toUpdate, setToUpdate] = useState([]);
  const [toCreate, setToCreate] = useState([]);
  const [displayCount, setDisplayCount] = useState();

  const PromptUpdater = () => {
    useEffect(() => {
      const pageUrl = window.location.href;
      if (displayActionBar === true && pageUrl.includes("tab=skills")) {
        setShowNavPrompt(true);
      } else {
        setShowNavPrompt(false);
      }
    }, [displayActionBar]);

    return null;
  };

  useEffect(() => {
    if (!talentValues) return;
    setDisplayCount(
      talentValues.filter((s) => s.display_on_talent_profile === true).length
    );
  }, [talentValues]);

  useEffect(() => {
    if (!toCreate || !toUpdate) setDisplayActionBar(false);
    toUpdate.length || toCreate.length
      ? setDisplayActionBar(true)
      : setDisplayActionBar(false);
  }, [toCreate, toUpdate]);

  const specialtiesArray = useMemo(() => {
    if (!talentData) return;
    if (!talentData.specialties?.length) {
      setOpen("other");
      return [];
    } else {
      const arr = talentData.specialties.split("|");
      return arr;
    }
  }, [talentData]);

  const [specialtySkills, otherSkills] = useMemo(() => {
    if (!rsuQualifications) return [[], []];

    const skills = rsuQualifications?.filter(
      (q) => q.type.toLowerCase() === "skill"
    );

    // Split apart those that are specialties and those that are not
    // Then sort them by the talent's current rating and alphabetically
    const specialtySkills = sortQualifications(
      skills.filter((s) =>
        specialtiesArray.some((q) => s.grouping.includes(q))
      ),
      talentSkills
    );

    const otherSkills = sortQualifications(
      skills.filter(
        (s) => !specialtiesArray.some((q) => s.grouping.includes(q))
      ),
      talentSkills
    );

    return [specialtySkills, otherSkills];
  }, [rsuQualifications, talentSkills, specialtiesArray]);

  const onRatingChange = (id, rating) => {
    handleAssessmentRatingChange(
      id,
      rating,
      talentSkills,
      talentValues,
      setTalentValues,
      toCreate,
      setToCreate,
      toUpdate,
      setToUpdate,
      displayCount
    );
  };

  const onSkillCheckboxClick = (id) => {
    return onCheckboxClick(
      id,
      talentValues,
      setTalentValues,
      toCreate,
      setToCreate,
      toUpdate,
      setToUpdate
    );
  };

  const onClickSave = () => {
    const response = onUpdate(toCreate, toUpdate);
    if (response) {
      setToUpdate([]);
      setToCreate([]);
      setDisplayActionBar(false);
    }
  };

  const onClickCancel = () => {
    setToUpdate([]);
    setToCreate([]);
    setTalentValues([...talentSkills]);
    setDisplayActionBar(false);
  };

  return (
    <>
      <div className="flex flex-col">
        {specialtiesArray?.length > 0 && (
          <QualificationsListView
            title={$.specialty_skills_label}
            qualifications={specialtySkills}
            talentValues={talentValues}
            isOpen={open === "specialty"}
            onToggleOpen={() =>
              setOpen(open === "specialty" ? "" : "specialty")
            }
            onRatingChange={onRatingChange}
            onCheckboxClick={onSkillCheckboxClick}
            isUpdating={isUpdating}
            displayCount={displayCount}
            maxDisplayCount={20}
            ratingHelperLabel="Skill"
          />
        )}
        <QualificationsListView
          title={
            specialtiesArray?.length > 0
              ? $.other_skills_subheader
              : $.skills_title
          }
          qualifications={otherSkills}
          talentValues={talentValues}
          isOpen={open === "other"}
          onToggleOpen={() => setOpen(open === "other" ? "" : "other")}
          onRatingChange={onRatingChange}
          onCheckboxClick={onSkillCheckboxClick}
          isUpdating={isUpdating}
          displayCount={displayCount}
          maxDisplayCount={20}
          ratingHelperLabel="Skill"
        />
      </div>
      <ActionBar
        displayActionBar={displayActionBar}
        isUpdating={isUpdating}
        onClickCancel={onClickCancel}
        onClickSave={onClickSave}
      />
      <PromptUpdater />
    </>
  );
};

export default Skills;
