import React, { useRef, useState } from "react";
import clsx from "clsx";
import { admin_project_overview as $ } from "strings";
import RichTextEditor from "components/RichTextEditorNew";
import UilArrow from "@iconscout/react-unicons/icons/uil-arrow-circle-right";
import { isBodyEmpty } from "components/RichTextEditor";
import AlertError from "components/AlertError";
import { useAlert } from "react-alert";
import { deserialize, serialize } from "utils/htmlSerializer";
import { patchProjectNote, patchStatusNote } from "utils/adminApi";

const EditNoteForm = ({ note, updateNote, setEditing }) => {
  const alert = useAlert();
  const [saving, setSaving] = useState();
  const ref = useRef();

  const [values, setValues] = useState({
    body: deserialize(
      new DOMParser().parseFromString(
        note.note_body && note.note_body.includes("<p>")
          ? note.note_body
          : (note.note_body || "")
              .split("\n")
              .map((t) => "<p>" + t + "</p>")
              .join(""),
        "text/html"
      ).body
    )
  });

  const updateVal = (k, v) => {
    setValues((vs) => ({ ...vs, [k]: v }));
  };

  const isEmpty = isBodyEmpty(values.body);

  const onSubmit = async () => {
    setSaving(true);

    const newBody = serialize({ children: values.body });

    if (newBody === note.note_body) {
      setSaving(false);
      setEditing(false);
      return;
    }

    const call = async () => {
      try {
        if (note.type === "status_history") {
          const n = await patchStatusNote(note.id, { description: newBody });
          updateNote(note.id, {
            note_body: n.description,
            updated_at: n.updated_at
          });
        } else {
          const n = await patchProjectNote(note.id, { body: newBody });
          updateNote(note.id, {
            note_body: n.body,
            updated_at: n.updated_at
          });
        }
        setEditing(false);
      } catch (e) {
        setSaving(false);
        console.error(e);
        alert.error(<AlertError error={e} />);
      }
    };

    await call();
  };

  return (
    <div ref={ref} className="h-full">
      <RichTextEditor
        alwaysOpen
        value={values.body}
        onChange={(v) => updateVal("body", v)}
      />
      <div className="flex items-center justify-start rounded-b border-b border-l border-r border-geyser px-2 pb-1">
        <div className="flex flex-1 items-center justify-end">
          {!isEmpty && (
            <button
              onClick={() => {
                setEditing(false);
              }}
              type="button"
              className="flex items-center text-sm font-bold text-kasmir"
            >
              {$.cancel_btn}
            </button>
          )}
          <button
            onClick={onSubmit}
            type="button"
            className={clsx(
              "ml-4 flex items-center text-sm font-bold",
              saving || isEmpty ? "cursor-not-allowed text-caded" : "text-link"
            )}
            disabled={saving || isEmpty}
          >
            {saving ? $.saving_btn : $.save_btn}
            <UilArrow size="20" className="ml-1" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditNoteForm;
