import React, { useState } from "react";
import clsx from "clsx";
import { admin_company_dataimport as $ } from "strings";
import { EditBtn, Button } from "components/Buttons";
import { toFormat } from "utils/date";
import { patchCompanyNote } from "utils/adminApi";
import useConfirmClose from "hooks/useConfirmClose";
import { Prompt } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import AlertError from "components/AlertError";
import { Label, Error } from "components/Form";
import { useAlert } from "react-alert";
import useIsOverflow from "hooks/useIsOverflow";
import RichTextEditor from "components/RichTextEditor";
import { deserialize, serialize } from "utils/htmlSerializer";
import { urlify } from "utils/str";

const Footer = ({ note, editable, setEditing, children }) => (
  <div className="mt-4 flex items-center justify-between border-t border-link-water pt-2">
    <div className="flex-1 text-sm font-bold text-midnight">
      {note.created_by === "Crelate_Populate_Companies_Script"
        ? "Crelate Tags"
        : note.created_by || $.no_author_label}
    </div>
    <div className="">{children}</div>
    <div className="flex flex-1 items-center justify-end">
      <div className="group relative">
        {note.created_on && (
          <div className="text-xs font-bold text-midnight">
            {toFormat(new Date(note.created_on), "MMMM dd, yyyy 'at' h:mm a")}
          </div>
        )}
        {note.updated_at && (
          <div className="trans-left-half absolute bottom-full left-half z-30 mb-2 hidden w-content rounded bg-cove px-1 py-2 text-center text-xs font-bold text-white group-hover:inline">
            {$.last_update_label}
            <br />
            {toFormat(new Date(note.updated_at), "MMMM dd, yyyy 'at' h:mm a")}
          </div>
        )}
      </div>
      {note.isTmp && (
        <span className="mx-2 text-xs text-kasmir">Saving...</span>
      )}
      {editable && !note.isTmp && <EditBtn onClick={() => setEditing(true)} />}
    </div>
  </div>
);

const Note = ({ note, editable, setData }) => {
  const alert = useAlert();
  const [editing, setEditing] = useState();
  const [showAll, setShowAll] = useState(false);

  const { handleSubmit, errors, formState, control } = useForm();
  const { canClose, confirmMsg } = useConfirmClose(formState.isDirty);

  const ref = React.useRef();
  const isOverflow = useIsOverflow(ref, (v) => {});

  const onSubmit = async (values) => {
    setEditing(false);

    const newBody = serialize({ children: values.body });

    if (newBody === note.body) {
      return;
    }

    setData((data) => {
      const i = data.results.findIndex((n) => n.id === note.id);
      const results = [...data.results];
      results[i].body = newBody;

      return { ...data, results };
    });

    const call = async () => {
      try {
        await patchCompanyNote(note.id, { ...values, body: newBody });
      } catch (e) {
        console.error(e);
        alert.error(<AlertError error={e} />);
      }
    };

    await call();
  };

  return (
    <div className="relative flex">
      <div className="absolute left-0 top-0 ml-2 mt-5 h-full border-l border-dashed border-geyser" />
      <div className="mr-4 mt-3 pt-1">
        <div className="relative h-4 w-4 rounded-full border-2 border-white bg-link" />
      </div>
      <div className="relative my-2 flex-1 rounded border border-link-water p-4">
        {!editing && (
          <div className="mt-2">
            <div className="text-sm font-bold text-midnight">
              <div
                ref={ref}
                className={clsx(
                  "rich-content min-h-0 whitespace-pre-line",
                  showAll ? "" : "max-h-10 overflow-y-hidden"
                )}
              >
                <div
                  className="text-sm font-normal"
                  dangerouslySetInnerHTML={{ __html: urlify(note.body) }}
                />
              </div>
            </div>
            <Footer note={note} editable={editable} setEditing={setEditing}>
              {(isOverflow || showAll) && (
                <button
                  className="focus:outline-none outline-none mx-auto w-full rounded-sm px-2 py-2 text-center text-sm font-bold text-link hover:bg-link-water"
                  onClick={() => setShowAll(!showAll)}
                >
                  {showAll ? "VIEW LESS" : "VIEW MORE"}
                </button>
              )}
            </Footer>
          </div>
        )}
        {editing && (
          <div>
            <form
              className="flex min-h-0 flex-1 flex-col flex-nowrap"
              onSubmit={handleSubmit(onSubmit)}
              noValidate
            >
              <Prompt when={formState.isDirty} message={confirmMsg} />
              <div className="-mr-8 min-h-0 flex-1 overflow-y-auto py-4">
                <div className="flex flex-wrap">
                  <div className="w-full pr-8">
                    <Label>{$.body_input}</Label>
                    <Controller
                      defaultValue={deserialize(
                        new DOMParser().parseFromString(
                          note.body && note.body.includes("<p>")
                            ? note.body
                            : (note.body || "")
                                .split("\n")
                                .map((t) => "<p>" + t + "</p>")
                                .join(""),
                          "text/html"
                        ).body
                      )}
                      control={control}
                      rules={{ required: $.validation_required }}
                      as={<RichTextEditor />}
                      onFocusChange={() => {}}
                      tabIndex={0}
                      placeholder={$.body_placeholder}
                      name="body"
                      error={errors.body}
                    />
                    {errors.body && <Error>{errors.body.message}</Error>}
                  </div>
                </div>
              </div>
              <div className="flex items-center justify-end">
                <Button
                  secondary
                  onClick={() => {
                    if (canClose()) {
                      setEditing(false);
                    }
                  }}
                  className="mr-4"
                >
                  {$.cancel_button}
                </Button>
                <Button type="submit" disabled={formState.isSubmitting}>
                  {$.save_button}
                </Button>
              </div>
            </form>
          </div>
        )}
      </div>
    </div>
  );
};

export default Note;
