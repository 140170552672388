import React, { useEffect, useState } from "react";
import { admin_login as $ } from "strings";
import { resetPassword } from "utils/api";
import { useAlert } from "react-alert";
import AlertError from "components/AlertError";
import { Input } from "../../components/Form";
import { useHistory } from "react-router-dom";
import AlertSuccess from "../../components/AlertSuccess";
import { Button } from "./components";
import AuthLayout from "./components/AuthLayout";

const TalentResetPassword = ({ setLogged }) => {
  const [email, setEmail] = useState();
  useEffect(() => {
    document.title = "Reset Password";
  }, []);

  const alert = useAlert();
  const history = useHistory();

  const handleResetPassword = async () => {
    try {
      await resetPassword({ email });
      alert.success(<AlertSuccess message={$.password_reset_success} />, {
        timeout: 2000
      });
      history.push(`/talent/reset-password-success?email=${email}`);
    } catch (e) {
      alert.error(
        <AlertError message={e.response?.data?.error || $.auth_error} />
      );
    }
  };

  return (
    <AuthLayout>
      <div className="flex h-full flex-col items-center justify-center">
        <h1 className="mb-1 text-center text-2xl font-semibold">
          Enter your email address
        </h1>
        <h2 className="text-md mb-4 text-center text-kasmir">
          We will send you an email with a link to reset your password
        </h2>
        <label className="mb-1 w-full text-left text-sm">Email</label>
        <div className="mb-6 w-full">
          <Input
            type="email"
            fontSize="text-sm"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <Button className="w-full" onClick={handleResetPassword} large>
          Reset password
        </Button>
        <p className="mb-4 mt-8 w-full text-center text-sm text-kasmir">
          Having trouble logging in?{" "}
          <a
            href="mailto:talent@rightsideup.co"
            className="text-electric-indigo underline hover:text-electric-indigo-lighter"
          >
            Contact support
          </a>
        </p>
      </div>
    </AuthLayout>
  );
};

export default TalentResetPassword;
