import React from "react";

import clsx from "clsx";

/**
 * Renders a wrapper box, that handles some basic alignments, like centering and resizing.
 * @param {component} children - The children to render.
 * @param {string} className - Classes to append to the wrapper default classes.
 */
const Wrapper = ({ children, className = "" }) => (
  <div className={clsx("container m-auto", className)}>{children}</div>
);

/**
 * Renders a media object (Content with content at left and/or right).
 * @param {component} children - The children to render, this is meant to be a combination of media left/right/body components.
 * @param {string} className - Classes to append to the default classes.
 */
const Media = ({ children, className = "" }) => (
  <div className={clsx("flex", className)}>{children}</div>
);

/**
 * Renders the left part of a media component, like a user's photo.
 * @param {component} children - The children to render to the left of the media object.
 */
const MediaLeft = ({ children, className = "" }) => (
  <div className={className || "pr-4"}>{children}</div>
);

/**
 * Renders the main content in a media object, like a comment, note or information.
 * @param {component} children - The children to render.
 * @param {string} className - Classes to append to the default classes.
 */
const MediaBody = ({ children, className = "" }) => (
  <div className={clsx("flex-1", className)}>{children}</div>
);

/**
 * Renders the right part of a media component, like metadata, dates.
 * @param {component} children - The children to render to the right of the media object.
 */
const MediaRight = ({ children }) => <div className="pl-4">{children}</div>;

export { Wrapper, Media, MediaLeft, MediaBody, MediaRight };
