import React, { useState, useMemo } from "react";
import $ from "strings/talent";
import { Label, Sublabel } from "components/Form";
import { Error } from "../../components/index";
import QualificationsListView from "../../components/QualificationsListView";
import { handleRatingChange } from "../common";

const Tools = ({
  setFieldValue,
  values,
  errors,
  qualifications,
  setFieldError
}) => {
  const [open, setOpen] = useState("specialty");

  const specialtiesArray = useMemo(() => {
    if (!values?.specialties?.length) {
      setOpen("other");
      return [];
    } else {
      const arr = values.specialties.split("|");
      return arr;
    }
  }, [values]);

  const [specialtyTools, otherTools] = useMemo(() => {
    if (!qualifications) return [[], []];

    const tools = qualifications.filter((q) => q.type.toLowerCase() === "tool");

    // Split apart those that are specialties and those that are not
    // Then sort them alphabetically (no ratings yet in onboarding)
    const specialtyTools = tools
      .filter((s) => specialtiesArray.some((q) => s.grouping.includes(q)))
      .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));

    const otherTools = tools
      .filter((s) => !specialtiesArray.some((q) => s.grouping.includes(q)))
      .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));

    return [specialtyTools, otherTools];
  }, [qualifications, specialtiesArray]);

  const onRatingChange = (id, rating) => {
    handleRatingChange(
      id,
      rating,
      values,
      "toolsRatings",
      setFieldValue,
      errors,
      setFieldError
    );
  };

  // Convert ratings object to array format expected by QualificationsListView
  const talentValues = useMemo(() => {
    return Object.entries(values.toolsRatings || {}).map(
      ([qualification_id, talent_rating]) => ({
        qualification_id,
        talent_rating
      })
    );
  }, [values.toolsRatings]);

  return (
    <div className="grow px-8 py-4">
      <div className="mb-auto py-2">
        <div className="py-2 text-2xl font-bold text-darkest-navy sm:text-3xl">
          {$.tools_title}
        </div>
        <div className="text-base font-normal text-midnight sm:text-lg">
          {$.expertise_subtitle}
        </div>
      </div>
      <div className="flex flex-col">
        <div className="mb-2">
          <Label className="text-sm font-medium text-darkest-navy sm:text-base">
            {$.tools_label}
          </Label>
          <Sublabel>{$.rate_any_sublabel}</Sublabel>
        </div>
        {specialtiesArray?.length > 0 && (
          <QualificationsListView
            title={$.specialty_tools_label}
            qualifications={specialtyTools}
            talentValues={talentValues}
            isOpen={open === "specialty"}
            onToggleOpen={() =>
              setOpen(open === "specialty" ? "" : "specialty")
            }
            onRatingChange={onRatingChange}
            isUpdating={false}
            showCheckbox={false}
            ratingHelperLabel="Tool"
            showDisplayOnProfile={false}
            theme="white"
          />
        )}
        <QualificationsListView
          title={
            specialtiesArray?.length > 0
              ? $.other_tools_subheader
              : $.tools_title
          }
          qualifications={otherTools}
          talentValues={talentValues}
          isOpen={open === "other"}
          onToggleOpen={() => setOpen(open === "other" ? "" : "other")}
          onRatingChange={onRatingChange}
          isUpdating={false}
          showCheckbox={false}
          ratingHelperLabel="Tool"
          showDisplayOnProfile={false}
          theme="white"
        />
      </div>
      {errors.toolsRatings ? (
        <Error msg={errors.toolsRatings} />
      ) : (
        <div style={{ height: "22px" }} />
      )}
    </div>
  );
};

export default Tools;
