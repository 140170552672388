import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { admin_contact as $ } from "strings";
import { useParams } from "react-router-dom";
import { Prompt } from "react-router-dom";
import RichTextEditor from "components/RichTextEditorNew";
import UilArrow from "@iconscout/react-unicons/icons/uil-arrow-circle-right";
import { isBodyEmpty } from "components/RichTextEditor";
import { getAdminUser } from "utils/localStorageService";
import AlertError from "components/AlertError";
import { useAlert } from "react-alert";
import { serialize } from "utils/htmlSerializer";
import { postContactNoteV2 } from "utils/adminApi";
import { usePageCache } from "contexts/pageCacheContext";
import useConfirmClose from "hooks/useConfirmClose";

const NoteForm = ({ data, defaultContactId, addNote }) => {
  const user = getAdminUser();
  const alert = useAlert();
  const [saving, setSaving] = useState();
  const { set, cache } = usePageCache();
  const { id } = useParams();
  const [values, setValues] = useState(
    cache["admin_contact_note_draft__" + id] || {
      body: [{ type: "paragraph", children: [{ text: "" }] }]
    }
  );

  const updateVal = (k, v) => {
    setValues((vs) => ({ ...vs, [k]: v }));
  };

  const isEmpty = isBodyEmpty(values.body);
  const { canClose, confirmMsg } = useConfirmClose(!isEmpty);

  const onSubmit = async () => {
    setSaving(true);

    let body = isEmpty ? [] : values.body;

    const toSend = {
      body: serialize({ children: body }),
      contact_id: defaultContactId
    };

    const call = async () => {
      try {
        const n = await postContactNoteV2(toSend);
        setValues({ body: [{ type: "paragraph", children: [{ text: "" }] }] });
        addNote({
          body: n.body,
          created_by: n.created_by,
          id: n.id,
          created_on: n.created_on,
          updated_at: n.updated_at
        });
      } catch (e) {
        console.error(e);
        alert.error(<AlertError error={e} />);
      }
      setSaving(false);
    };

    await call();
  };

  return (
    <>
      <Prompt when={!isEmpty} message={confirmMsg} />
      <RichTextEditor
        placeholder={`Save Information to ${data.first_name} ${data.last_name}`}
        value={values.body}
        onChange={(v) => updateVal("body", v)}
        isStageChangeNote={false}
      />
      <div className="flex items-center justify-start rounded-b border-b border-l border-r border-geyser px-2 pb-1">
        <div className="flex flex-1 items-center justify-end">
          {!isEmpty && (
            <button
              onClick={() => {
                if (canClose()) {
                  updateVal("body", [
                    { type: "paragraph", children: [{ text: "" }] }
                  ]);
                  setValues({
                    body: [{ type: "paragraph", children: [{ text: "" }] }]
                  });
                  set("admin_contact_note_draft__" + defaultContactId, null);
                }
              }}
              type="button"
              className="flex items-center text-sm font-bold text-kasmir"
            >
              {$.cancel_btn}
            </button>
          )}
          <button
            type="button"
            className={clsx(
              "ml-4 flex items-center text-sm font-bold",
              saving || isEmpty ? "cursor-not-allowed text-caded" : "text-link"
            )}
            disabled={saving || isEmpty}
          >
            {saving ? $.saving_btn : $.submit_btn}
            <UilArrow size="20" className="ml-1" />
          </button>
        </div>
      </div>
    </>
  );
};

export default NoteForm;
