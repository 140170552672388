import React, {useEffect} from "react";
import {useParams} from "react-router-dom";
import {impersonateStart} from "../../../../utils/localStorageService";
import AlertError from "../../../../components/AlertError";
import {useAlert} from "react-alert";
import {impersonate} from "../../../../utils/adminApi";

const Page = ({ setLogged, setAdminLogged }) => {
  const { id } = useParams();
  const alert = useAlert();

  const handleLoginResponse = (response) => {
    impersonateStart(response);
    if (response.user) {
      setAdminLogged(true);
      window.location.href = `/admin`;
    } else if (response.talent) {
      setLogged(true);
      if (!response.talent.skills_survey_submitted_at) {
        window.location.href = `/talent/onboard?user=${response.talent.user.id}`;
      } else {
        window.location.href = `/talent/${response.talent.url_suffix}`;
      }
    }
  };

  const handleLoginError = (e) => {
    let msg = e.response?.data?.error || e.message || 'Unable to login';
    let href = '/';
    if (e.response?.status === 401) {
      msg = 'Please log in first';
      href = '/login';
    }
    alert.error(<AlertError message={msg} />, {
      timeout: 3000,
      onClose: () => {
        window.location.href = href;
      }
    });
  };

  useEffect(() => {
    impersonate(id).then(handleLoginResponse).catch(handleLoginError);
  }, [id]);

  return <></>;
};

export default Page;
