import React, { useEffect, useState } from "react";
import $ from "strings/talent";
import { Textarea } from "components/Form";
import {
  FormErrorMessage,
  Input,
  Label,
  PointedTooltip,
  SettingsActionBar,
  Sublabel
} from "../components";
import { Form, Formik, useFormikContext } from "formik";
import { patchTalentTalent } from "../../../utils/api";
import AlertError from "../../../components/AlertError";
import * as yup from "yup";
import Toggle from "../components/Toggle";
import { useHistory } from "react-router-dom";
import { getTalentProfileCompletion } from "utils/misc";
import { useAlert } from "react-alert";
import { setUrlSuffix } from "../../../utils/localStorageService";

const SearchEngineOptimization = ({
  talentData,
  setTalentData,
  onUpdate,
  setShowNavPrompt
}) => {
  const [userId, setUserId] = useState();
  const [completionPercentage, setCompletionPercentage] = useState(0);
  const [showCompletionTooltip, setShowCompletionTooltip] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [initialValues, setInitialValues] = useState({
    urlSuffix: "",
    pageTitle: "",
    pageDescription: ""
  });

  const history = useHistory();

  const PromptUpdater = () => {
    const { dirty } = useFormikContext();
    useEffect(() => {
      const pageUrl = window.location.href;
      if (typeof dirty !== "undefined") {
        if (dirty === true && pageUrl.includes("tab=profile_settings:seo")) {
          setShowNavPrompt(true);
        } else {
          setShowNavPrompt(false);
        }
      }
    }, [dirty]);

    return null;
  };

  useEffect(() => {
    window.document.onclick = () => {
      setShowCompletionTooltip(false);
    };
  }, []);

  useEffect(() => {
    if (!talentData) {
      return;
    }
    setInitialValues({
      urlSuffix: talentData.url_suffix || "",
      pageTitle: talentData.page_title || "",
      pageDescription: talentData.page_description || ""
    });
    setUserId(talentData.user?.id);
    const percentage = getTalentProfileCompletion(talentData);
    setCompletionPercentage(percentage);
  }, [talentData]);

  const alert = useAlert();

  const descriptionMinLength = 70;
  const descriptionMaxLength = 155;
  const pageDescriptionErrorMsg = `URL description must be between ${descriptionMinLength}-${descriptionMaxLength} characters including spaces`;

  const validationSchema = yup.object().shape({
    urlSuffix: yup.string().required("This field is required"),
    pageTitle: yup
      .string()
      .required("This field is required")
      .max(60, "Link title should be under 60 characters"),
    pageDescription: yup
      .string()
      .max(descriptionMaxLength, pageDescriptionErrorMsg)
      .min(descriptionMinLength, pageDescriptionErrorMsg)
  });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={() => {}}
      enableReinitialize={true}
    >
      {({
        setFieldValue,
        values,
        touched,
        setTouched,
        setFieldTouched,
        errors,
        setFieldError,
        validateForm
      }) => {
        const onChange = async (e) => {
          const { name, value } = e.target;
          await setFieldValue(name, value);
          await setFieldTouched(name, value !== initialValues[name]);
          if (errors[name]) {
            setFieldError(name, null);
          }
        };

        const updateTalent = async () => {
          // only values that have changed
          const mapping = {
            urlSuffix: "url_suffix",
            pageTitle: "page_title",
            pageDescription: "page_description"
          };
          const data = {};
          for (let [key, value] of Object.entries(values)) {
            if (value !== initialValues[key]) {
              data[mapping[key]] = value;
            }
          }
          if (!Object.keys(data).length) {
            return;
          }
          try {
            const response = await patchTalentTalent(userId, data);
            if (data.url_suffix) {
              const newSuffix = data.url_suffix;
              const oldSuffix = initialValues.urlSuffix;
              if (newSuffix !== oldSuffix) {
                setUrlSuffix(newSuffix);
                // redirect to new url - refresh necessary
                window.location.href = window.location.href.replace(
                  oldSuffix,
                  newSuffix
                );
              }
            }
            setTalentData(response);
            setInitialValues(values);
            onUpdate();
          } catch (e) {
            if (e?.response?.data?.url_suffix) {
              let message =
                "This URL is already taken. Please choose another one.";
              setFieldError("urlSuffix", message);
            } else {
              alert.error(
                <AlertError message="Something went wrong. Please try again, or contact us if the issue continues." />
              );
            }
          }
        };

        return (
          <Form className="form">
            <div className="mb-2 sm:w-2/3">
              <Label>{$.optimize_seo_label}</Label>
              <Sublabel>{$.optimize_seo_helper}</Sublabel>
              <div className="">
                <PointedTooltip
                  onClick={(e) => e.stopPropagation()}
                  hidden={!showCompletionTooltip}
                  msg={
                    <div>
                      Complete your profile in order to customize your public
                      URL or index your profile for search engine. Your profile
                      is&nbsp;
                      <button
                        className="font-semibold text-electric-indigo underline"
                        onClick={() =>
                          history.push(`/talent/${values.urlSuffix}/`)
                        }
                      >
                        {completionPercentage}% complete
                      </button>
                      .
                    </div>
                  }
                >
                  <Toggle
                    isUpdating={isUpdating}
                    isOn={!!talentData?.seo_on}
                    onClick={async (e) => {
                      if (completionPercentage === 100) {
                        try {
                          setIsUpdating(true);
                          const res = await patchTalentTalent(userId, {
                            seo_on: !talentData.seo_on
                          });
                          setTalentData(res);
                          // reset form to initial state
                          for (let [key, value] of Object.entries(
                            initialValues
                          )) {
                            await setFieldValue(key, value);
                            await setFieldTouched(key, false);
                          }
                        } finally {
                          setIsUpdating(false);
                        }
                      } else {
                        setShowCompletionTooltip(true);
                      }
                      e.stopPropagation();
                    }}
                  />
                </PointedTooltip>
              </div>
            </div>
            <div className="relative">
              {!talentData.seo_on && (
                <div className="absolute z-top h-full w-full bg-white opacity-50" />
              )}
              <div className="mb-4">
                <Label>{$.seo_preview_label}</Label>
                <div className="rounded-xl bg-lightest-grey p-3">
                  <div className="text-sm text-kasmir">
                    https://rightsideup.com/talent/{values.urlSuffix}
                  </div>
                  <div className="text-lg text-midnight">
                    {values.pageTitle}
                  </div>
                  <div className="text-sm text-midnight sm:w-2/3">
                    {values.pageDescription}
                  </div>
                </div>
              </div>
              <div className="mb-2 flex flex-col gap-4 md:flex-row">
                <div className="flex-grow pr-4">
                  <Label>{$.seo_link_title_label}</Label>
                  <Input
                    name="pageTitle"
                    value={values.pageTitle}
                    error={errors.pageTitle}
                    onChange={onChange}
                  />
                  <FormErrorMessage error={errors.pageTitle} />
                </div>
                <div className="flex-grow">
                  <Label>{$.seo_custom_url_label}</Label>
                  <Input
                    name="urlSuffix"
                    prefix="https://rightsideup.com/talent/"
                    value={values.urlSuffix}
                    error={errors.urlSuffix}
                    onChange={onChange}
                  />
                  <FormErrorMessage error={errors.urlSuffix} />
                </div>
              </div>
              <div>
                <Label>{$.seo_url_description_label}</Label>
                <Textarea
                  type="textarea"
                  value={values.pageDescription}
                  error={errors.pageDescription}
                  name="pageDescription"
                  className="resize-none text-normal"
                  unbolded
                  maxLength={descriptionMaxLength}
                  onChange={onChange}
                />
                <FormErrorMessage error={errors.pageDescription} />
              </div>
              {Object.values(touched).some(Boolean) && (
                <SettingsActionBar
                  cancelDisabled={isUpdating}
                  saveDisabled={isUpdating}
                  onCancel={async () => {
                    for (let [key, value] of Object.entries(initialValues)) {
                      await setFieldValue(key, value);
                    }
                    await setTouched({});
                  }}
                  onSave={async () => {
                    setIsUpdating(true);
                    const errors = await validateForm();
                    if (!Object.keys(errors).length) {
                      await updateTalent();
                    }
                    setIsUpdating(false);
                  }}
                />
              )}
            </div>
            <PromptUpdater />
          </Form>
        );
      }}
    </Formik>
  );
};

export default SearchEngineOptimization;
