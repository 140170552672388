import React from "react";
import clsx from "clsx";
import Date from "react-datepicker";
import UilCalendar from "@iconscout/react-unicons/icons/uil-calender";
import { CalendarIcon } from "../pages/talent/onboard/icons/index";
/**
 * Renders an input, used internally for the DatePicker component, it is not meant to be used alone.
 */
const Input = React.forwardRef(
  (
    { value, onClick, error, disabled, placeholder, onClearClick, isV2 },
    ref
  ) => (
    <div className="relative">
      <input
        placeholder={placeholder}
        ref={ref}
        disabled={disabled}
        type="text"
        onClick={onClick}
        value={value}
        readOnly
        className={clsx(
          "placeholder-text-kasmir focus:outline-none my-1 flex h-10 w-full appearance-none items-center rounded bg-white pr-3 font-bold text-midnight placeholder:font-medium",
          isV2 ? "pl-3 text-sm sm:text-base" : "pl-8 text-sm",
          error
            ? "border-2 border-red"
            : disabled
              ? "cursor-not-allowed bg-link-water"
              : "border border-geyser focus:border-2 focus:border-link"
        )}
      />
      <button
        type="button"
        onClick={onClick}
        className={clsx(
          "focus:outline-none absolute bottom-0 top-0 ml-3 flex cursor-pointer appearance-none items-center text-lg",
          isV2 ? "right-3" : "left-0",
          disabled ? "text-geyser" : "text-kasmir"
        )}
      >
        {isV2 ? <CalendarIcon /> : <UilCalendar size="16" />}
      </button>
    </div>
  )
);

/**
 * Renders an input with a calendar picker.
 * It wrappers react-datepicker (https://reactdatepicker.com/). Can add more props for that library, but this component is customize to support monthly and daily, and some default styles.
 * @param {boolean} error - Flag to indicate error on this input.
 * @param {callback} onChange - Callback to catch new selected values.
 * @param {date} value - Input value, a date.
 * @param {boolean} monthly - Flag to indicate if is a monthly or daily input, by default monthly.
 * @param {object} props - Extra props to pass to the Date component.
 */
const DatePicker = ({
  error,
  onChange,
  value,
  monthly = true,
  isClearable = true,
  isV2 = false,
  ...props
}) => {
  return (
    <div className="relative block">
      <Date
        {...props}
        selected={value}
        onChange={onChange}
        showMonthYearPicker={monthly}
        showFullMonthYearPicker={monthly}
        isClearable={isClearable}
        clearButtonClassName="uil uil-times absolute w-4 h-4 right-0 top-0 bottom-0 text-lg flex items-center mr-1 mt-2 appearance-none cursor-pointer focus:outline-none"
        clearButtonTitle="Clear"
        customInput={
          <Input isV2={isV2} error={error} disabled={props.disabled} />
        }
        dateFormat={monthly ? "MMM, yyyy" : "MM/dd/yyyy"}
        popperClassName="z-40 bg-white text-midnight shadow overflow-hidden rounded text-sm"
      />
    </div>
  );
};

export default DatePicker;
