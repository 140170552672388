import React, { useEffect, useState } from "react";
import { admin_login as $ } from "strings";
import { resetPassword } from "utils/api";
import { useAlert } from "react-alert";
import AlertError from "components/AlertError";
import { useHistory } from "react-router-dom";
import { Button } from "./components";
import AuthLayout from "./components/AuthLayout";

const ResetPasswordSuccess = ({ setLogged }) => {
  const [email, setEmail] = useState("");
  useEffect(() => {
    document.title = "Password Reset Confirmation";
    const urlParams = new URLSearchParams(window.location.search);
    const email = urlParams.get("email");
    setEmail(email);
  }, []);

  const alert = useAlert();
  const history = useHistory();

  const handleResetPassword = async () => {
    if (!email) {
      history.push(`/login`);
      return;
    }
    try {
      const response = await resetPassword({ email });
      alert.success(response.message);
    } catch (e) {
      console.error(e);
      alert.error(<AlertError error={e} message={$.auth_error} />);
    }
  };

  return (
    <AuthLayout>
      <div className="flex h-full flex-col items-center justify-center">
        <h1 className="text-2xl font-semibold">Check your inbox</h1>
        <h2 className="text-md mb-4 text-center text-kasmir">
          An email has been sent to {email || "your inbox"}
        </h2>
        <div className="mb-4 w-full rounded-lg bg-lightest-grey p-4">
          <h3 className="text-md md:text-lg">
            Didn’t receive an email in your inbox?
          </h3>
          <p className="md:text-md text-sm text-kasmir">
            We can only send password reset instructions to registered emails
            with an existing account. If the email doesn't appear in your inbox
            or spam folder, please check the email you entered or click below to
            send a new email.
          </p>
        </div>
        <Button className="w-full" onClick={handleResetPassword} large>
          Resend Email
        </Button>
        <p className="mb-4 mt-4 w-full text-center text-sm text-kasmir">
          Having trouble logging in?{" "}
          <a
            href="mailto:talent@rightsideup.co"
            className="text-link underline hover:text-link-dark"
          >
            Contact support
          </a>
        </p>
      </div>
    </AuthLayout>
  );
};

export default ResetPasswordSuccess;
