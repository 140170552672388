import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import { useParams } from "react-router-dom";
import { useAlert } from "react-alert";
import { components } from "react-select";
import $ from "strings/talent.json";
import { getProjects } from "utils/adminApi";
import Select from "components/Select";
import { Checkbox, Dropdown, Textarea } from "components/Form";
import { Modal } from "pages/talent/components";
import { FormElement } from "pages/talent/Settings/components";
import { statusOptions as allStatusOptions } from "./constants";
import { stageOptions } from "utils/globalConsts";
import { postCandidateV2 } from "utils/adminApi";
import AlertError from "components/AlertError";

/**
 * Modal for adding a talent to a new project. Allows searching/selecting a project,
 * selecting a candidate status, and adding a note.
 */
const AddNewProjectModal = ({ onCancel, onSave, existingProjectIds }) => {
  const alert = useAlert();
  const { id: candidateId } = useParams();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const statusOptions = allStatusOptions.filter((s) =>
    ["new", "maybe", "hold", "short_list", "scheduled"].includes(s.value)
  );

  useEffect(() => {
    return () => {
      setIsSubmitting(false);
    };
  }, []);

  // The fetcher attached to the projects select component. A lot of this is
  // the same as the getActiveProjectsV2 function, but we filter out projects that
  // the talent is already in and have a couple other optional criteria specific
  // to this modal.
  const fetchProjects = async (search, { page }, showProjectsInAnyStage) => {
    const params = {
      ordering: "-last_updated",
      stage: showProjectsInAnyStage
        ? "pitching,active,shadow_pitched,matching_support,matching_no_support,sourcing,submitted,contracting,pipelines"
        : "matching_support",
      page_size: 25,
      page: 1
    };
    const response = await getProjects(search ? { ...params, search } : params);

    const options = response.results
      .filter((i) => !existingProjectIds.includes(i.id))
      .map((i) => ({
        stage: stageOptions.find((s) => s.value === i.stage)?.label,
        value: i.id,
        label: i.name
      }));

    return {
      options,
      hasMore: !!response.next,
      additional: { page: page + 1 }
    };
  };

  const onAddProject = async ({
    candidateId,
    projectId,
    candidateStatus,
    description
  }) => {
    setIsSubmitting(true);
    try {
      await postCandidateV2({
        candidate: candidateId,
        project: projectId,
        status: candidateStatus,
        applied: new Date(),
        description
      });
      onSave();
    } catch (e) {
      console.error(e);
      alert.error(<AlertError error={e} onRetry={onAddProject} />);
    }
  };

  const ProjectSelect = ({
    showProjectsInAnyStage,
    values,
    setFieldValue,
    errors
  }) => {
    return (
      <Select
        async
        loadOptions={(search, _, additional) =>
          fetchProjects(search, additional, showProjectsInAnyStage)
        }
        placeholder="Select a project or type to search"
        name={`projectName_${showProjectsInAnyStage ? "any" : "matching"}`}
        value={values.projectName}
        error={errors.project_name}
        onChange={(option) => {
          setFieldValue("projectId", option?.value);
          setFieldValue("projectName", option);
        }}
        components={{
          Option: ({ data, children, ...rest }) => (
            <components.Option {...rest}>
              <div className="flex items-center justify-between text-kasmir">
                {children}
                <div>Stage: {data.stage}</div>
              </div>
            </components.Option>
          )
        }}
      />
    );
  };

  return (
    <Formik
      initialValues={{
        candidate_status: "new",
        show_projects_in_any_stage: false
      }}
      onSubmit={() => {}}
    >
      {({ handleChange, setFieldValue, values, errors }) => (
        <Modal
          title="Shortlist Talent to Project"
          id="add-new-project-modal"
          onClose={onCancel}
          onClickCancel={onCancel}
          onClickSave={async () => {
            await onAddProject({
              candidateId: candidateId,
              projectId: values.projectId,
              candidateStatus: values.candidate_status,
              description: values.note
            });
          }}
          fullScreenOnMobile
          buttonsDisabled={isSubmitting}
        >
          <div>
            <FormElement
              label="Project Name"
              sublabel={
                'The list shows project in "Matching Support Please" by default.'
              }
            >
              {/* There are 2 of these so that the caching of the Select works properly when toggling between stages */}

              {values.show_projects_in_any_stage && (
                <ProjectSelect
                  showProjectsInAnyStage
                  values={values}
                  setFieldValue={setFieldValue}
                  errors={errors}
                />
              )}

              {!values.show_projects_in_any_stage && (
                <ProjectSelect
                  showProjectsInAnyStage={false}
                  values={values}
                  setFieldValue={setFieldValue}
                  errors={errors}
                />
              )}

              <div className="mt-2">
                <Checkbox
                  name="show_projects_in_any_stage"
                  value={values.show_projects_in_any_stage}
                  onChange={(e) =>
                    setFieldValue("show_projects_in_any_stage", e)
                  }
                  error={errors.show_projects_in_any_stage}
                >
                  Show projects in any stage
                </Checkbox>
              </div>
            </FormElement>

            <FormElement
              label="Candidate Status"
              sublabel="Select which status this talent should have for this project."
            >
              <Dropdown
                name="candidate_status"
                placeholder={$.experience_type_placeholder}
                options={statusOptions}
                value={values.candidate_status}
                error={errors.candidate_status}
                onChange={(option, action) =>
                  handleChange({
                    target: {
                      name: action.name,
                      value: option.value
                    }
                  })
                }
                className="font-bold"
              />
            </FormElement>
            <FormElement
              label="Note Description"
              sublabel="This note will be saved to the talent's profile and analyzed for future recommendations."
            >
              <Textarea
                placeholder="Share why this talent is a good fit for the project..."
                name="note"
                value={values.note}
                error={errors.note}
                onChange={handleChange}
              />
            </FormElement>
          </div>
        </Modal>
      )}
    </Formik>
  );
};

export default AddNewProjectModal;
