import React from "react";

import clsx from "clsx";

const Input = React.forwardRef(
  ({ children, type = "text", error, fontSize, fontWeight, ...props }, ref) => (
    <div
      className={clsx(
        props.className ? `${props.className} ` : "",
        `flex h-10 w-full items-center rounded bg-white px-1 text-midnight`,
        fontSize || "",
        fontWeight || "",
        error
          ? "border-2 border-red"
          : props.disabled
            ? "border border-geyser bg-link-water"
            : "border border-geyser focus:border-2 focus:border-link"
      )}
    >
      {props.prefix && (
        <span className="my-1 inline-flex h-8 items-center rounded bg-lightest-grey px-1.5 text-kasmir">
          {props.prefix}
        </span>
      )}
      <input
        ref={ref}
        {...props}
        className="placeholder-text-kasmir focus:outline-none ml-2 flex-auto appearance-none placeholder:font-normal"
        onWheel={(e) => e.target.blur()}
        type={type}
      />
    </div>
  )
);

export default Input;
