import React, { useState, useEffect } from "react";

import clsx from "clsx";

/**
 * Renders an editable text. When clicked it shows an input, and on unBlur shows the text back again.
 * It is used internally in the Range component.
 */
const Editable = ({
  text,
  defaultValue,
  error,
  onChange,
  textClass = "",
  inputClass = "",
  inputProps,
  type,
  afterText = "",
  beforeText = "",
  ...props
}) => {
  const [isEditing, setEditing] = useState(false);
  const [value, setValue] = useState();

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  const handleKeyDown = (e) => {
    const { key } = e;
    const keys = ["Escape", "Tab", "Enter"];

    if (keys.indexOf(key) > -1) {
      setEditing(false);
      onChange(value);
    }
  };

  return (
    <div {...props} className="h-5">
      {isEditing ? (
        <div
          onBlur={() => {
            onChange(value);
            setEditing(false);
          }}
          onKeyDown={(e) => handleKeyDown(e)}
          className="flex h-5 items-center text-sm font-medium"
        >
          {beforeText}
          <input
            onChange={(e) => setValue(e.target.value)}
            value={value}
            autoFocus
            type={type}
            {...props}
            className={clsx(
              inputClass,
              "placeholder-text-kasmir focus:outline-none h-5 appearance-none rounded bg-white px-0 py-0 text-center text-sm font-medium placeholder:font-medium",
              type === "number" ? "w-8" : "w-28"
            )}
          />
          {afterText}
        </div>
      ) : (
        <div
          onClick={() => {
            setEditing(true);
            setValue(defaultValue);
          }}
          className={clsx(
            textClass,
            "h-5 text-sm",
            !defaultValue && defaultValue !== 0
              ? "font-medium text-caded"
              : "font-bold text-midnight"
          )}
        >
          {text}
        </div>
      )}
    </div>
  );
};

export default Editable;
