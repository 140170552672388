import React from "react";
import clsx from "clsx";
import { B2DB } from "components/Typography";
import { Qualification, Pill } from "../components";
import UilDown from "@iconscout/react-unicons/icons/uil-angle-down";
import UilUp from "@iconscout/react-unicons/icons/uil-angle-up";

/**
 * Sort qualifications by rating, then alphabetically
 */
export const sortQualifications = (qualifications, talentQualifications) => {
  const rated = qualifications.map((q) => ({
    ...q,
    talent_rating:
      talentQualifications.find((ts) => ts.qualification_id === q.id)
        ?.talent_rating ?? 0
  }));

  // First rated then unrated, with highest rating first then alphabetical
  return rated.sort(
    (a, b) =>
      b.talent_rating - a.talent_rating ||
      a.name.toLowerCase().localeCompare(b.name.toLowerCase())
  );
};

/**
 * Column labels for the qualifications list view
 */
const ColumnLabels = ({ label, showDisplayOnProfile }) => {
  return (
    <div
      className={clsx(
        "grid grid-cols-5 text-mini text-kasmir",
        showDisplayOnProfile ? "sm:grid-cols-6" : ""
      )}
    >
      <div className="col-span-2 sm:col-span-3">{label} name</div>
      <div
        className={clsx(
          "col-span-3 mr-2 flex gap-2 sm:col-span-2",
          showDisplayOnProfile ? "ml-auto flex-1 sm:ml-0" : "ml-auto mr-0"
        )}
      >
        <span>Beginner</span>
        <span>Intermediate</span>
        <span>Expert</span>
      </div>
      {showDisplayOnProfile && (
        <div className="col-span1 ml-auto mr-2 hidden gap-2 text-right sm:visible sm:flex">
          <span>Display on profile page</span>
        </div>
      )}
    </div>
  );
};

/**
 * An expandable list view of qualifications and their ratings. Presents a rounded container
 * with a title and a count of qualifications. It can be expanded or collapsed.
 *
 * @param {string} title - The title of the qualifications list view
 * @param {array} qualifications - All the qualifications to display
 * @param {array} talentValues - The values that the talent has for each qualification
 * @param {boolean} isOpen - Whether the qualifications list view is open
 * @param {function} onToggleOpen - The function to call when the qualifications list view is toggled
 * @param {function} onRatingChange - The function to call when a rating is changed
 * @param {function} onCheckboxClick - The function to call when a checkbox is clicked
 * @param {boolean} isUpdating - Whether the qualifications list view is updating
 * @param {number} displayCount - The number of qualifications to display
 * @param {number} maxDisplayCount - The maximum number of qualifications to display
 * @param {string} ratingHelperLabel - The label for the column headers
 * @param {string} theme - The theme of the qualifications list view (grey or white)
 */
const QualificationsListView = ({
  title,
  qualifications,
  talentValues,
  isOpen,
  onToggleOpen,
  onRatingChange,
  onCheckboxClick,
  isUpdating,
  displayCount,
  maxDisplayCount,
  ratingHelperLabel,
  showDisplayOnProfile = true,
  theme = "grey" // grey or white
}) => {
  // We can have a grey container with white elements. Or a white container with grey elements.
  const containerColor = theme === "grey" ? "bg-lightest-grey" : "bg-white";
  const qualificationColor = theme === "grey" ? "bg-white" : "bg-lightest-grey";

  return (
    <div
      className={clsx(
        "ratingContainer mb-2 rounded-xl p-2 sm:px-4",
        containerColor
      )}
    >
      <div className="flex cursor-pointer items-center" onClick={onToggleOpen}>
        <B2DB className="flex flex-1 items-center gap-2">
          {title}
          <Pill value={qualifications.length} />
        </B2DB>
        {isOpen ? <UilUp size="24" /> : <UilDown size="24" />}
      </div>
      {isOpen && (
        <div className="ratings flex flex-col gap-2 overflow-y-scroll py-2">
          <ColumnLabels
            label={ratingHelperLabel}
            showDisplayOnProfile={showDisplayOnProfile}
          />
          {qualifications.map((q) => (
            <Qualification
              key={q.id}
              backgroundColor={qualificationColor}
              qualification={q}
              rating={
                talentValues.find((qual) => qual.qualification_id === q.id)
                  ?.talent_rating || null
              }
              onRatingChange={onRatingChange}
              showCheckbox={showDisplayOnProfile}
              disabled={isUpdating}
              checkboxDisabled={
                isUpdating ||
                !talentValues.find((qual) => qual.qualification_id === q.id)
                  ?.talent_rating ||
                talentValues.find((qual) => qual.qualification_id === q.id)
                  ?.talent_rating === 0 ||
                (displayCount >= maxDisplayCount &&
                  talentValues.find((qual) => qual.qualification_id === q.id)
                    ?.display_on_talent_profile === false)
              }
              onCheckboxClick={() => onCheckboxClick(q.id)}
              checked={
                talentValues.find((qual) => qual.qualification_id === q.id)
                  ?.display_on_talent_profile || false
              }
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default QualificationsListView;
