import React, { useMemo, useState, useEffect } from "react";
import ActionBar from "./ActionBar";
import { onCheckboxClick, handleAssessmentRatingChange } from "./common";
import QualificationsListView, {
  sortQualifications
} from "../components/QualificationsListView";

const HIDDEN_INDUSTRY_IDS = [
  "57ddc7ac-da7e-4eee-946c-0b697e0819e8" // International Talent - not user facing
];

const Industries = ({
  rsuQualifications,
  talentIndustries,
  talentCompanyStages,
  onUpdate,
  isUpdating,
  setShowNavPrompt
}) => {
  const [open, setOpen] = useState("industry");
  const [talentIndustryValues, setTalentIndustryValues] = useState([
    ...talentIndustries
  ]);
  const [talentCompanyStageValues, setTalentCompanyStageValues] = useState([
    ...talentCompanyStages
  ]);
  const [displayActionBar, setDisplayActionBar] = useState(false);
  const [toUpdate, setToUpdate] = useState([]);
  const [toCreate, setToCreate] = useState([]);
  const [displayCount, setDisplayCount] = useState();

  const PromptUpdater = () => {
    useEffect(() => {
      const pageUrl = window.location.href;
      if (displayActionBar === true && pageUrl.includes("tab=industries")) {
        setShowNavPrompt(true);
      } else {
        setShowNavPrompt(false);
      }
    }, [displayActionBar]);

    return null;
  };

  useEffect(() => {
    if (!talentIndustryValues || !talentCompanyStageValues) return;
    setDisplayCount(
      talentIndustryValues.filter((s) => s.display_on_talent_profile === true)
        .length +
        talentCompanyStageValues.filter(
          (s) => s.display_on_talent_profile === true
        ).length
    );
  }, [talentIndustryValues, talentCompanyStageValues]);

  useEffect(() => {
    const pageUrl = window.location.href;
    if (pageUrl.includes("tab=industries:companyStage")) {
      setOpen("companyStage");
    } else if (pageUrl.includes("tab=industries:industry")) {
      setOpen("industry");
    }
  }, []);

  useEffect(() => {
    if (!toCreate || !toUpdate) setDisplayActionBar(false);
    toUpdate.length || toCreate.length
      ? setDisplayActionBar(true)
      : setDisplayActionBar(false);
  }, [toCreate, toUpdate]);

  const [industries, companyStages] = useMemo(() => {
    if (!rsuQualifications) return [[], []];

    const industries = rsuQualifications.filter(
      (q) =>
        HIDDEN_INDUSTRY_IDS.indexOf(q.id) === -1 &&
        q.type.toLowerCase() === "industry"
    );

    const stages = rsuQualifications.filter(
      (q) => q.type.toLowerCase() === "company_stage"
    );

    return [
      sortQualifications(industries, talentIndustries),
      sortQualifications(stages, talentCompanyStages)
    ];
  }, [rsuQualifications, talentIndustries, talentCompanyStages]);

  // INFO: industries are qualifications
  const onIndustryRatingChange = (id, rating) => {
    handleAssessmentRatingChange(
      id,
      rating,
      talentIndustries,
      talentIndustryValues,
      setTalentIndustryValues,
      toCreate,
      setToCreate,
      toUpdate,
      setToUpdate,
      displayCount
    );
  };

  const onCompanyStageRatingChange = (id, rating) => {
    handleAssessmentRatingChange(
      id,
      rating,
      talentCompanyStages,
      talentCompanyStageValues,
      setTalentCompanyStageValues,
      toCreate,
      setToCreate,
      toUpdate,
      setToUpdate,
      displayCount
    );
  };

  const onIndustryCheckboxClick = (id) => {
    return onCheckboxClick(
      id,
      talentIndustryValues,
      setTalentIndustryValues,
      toCreate,
      setToCreate,
      toUpdate,
      setToUpdate
    );
  };

  const onCompanyStageCheckboxClick = (id) => {
    return onCheckboxClick(
      id,
      talentCompanyStageValues,
      setTalentCompanyStageValues,
      toCreate,
      setToCreate,
      toUpdate,
      setToUpdate
    );
  };

  const onClickSave = () => {
    const response = onUpdate(toCreate, toUpdate);
    if (response) {
      setToUpdate([]);
      setToCreate([]);
      setDisplayActionBar(false);
    }
  };

  const onClickCancel = () => {
    setToUpdate([]);
    setToCreate([]);
    setTalentIndustryValues([...talentIndustryValues]);
    setTalentCompanyStageValues([...talentCompanyStageValues]);
    setDisplayActionBar(false);
  };

  return (
    <>
      <div className="flex flex-col">
        <QualificationsListView
          title="Industries and Business Models"
          qualifications={industries}
          talentValues={talentIndustryValues}
          isOpen={open === "industry"}
          onToggleOpen={() => setOpen(open === "industry" ? "" : "industry")}
          onRatingChange={onIndustryRatingChange}
          onCheckboxClick={onIndustryCheckboxClick}
          isUpdating={isUpdating}
          displayCount={displayCount}
          maxDisplayCount={10}
          ratingHelperLabel="Industry"
        />
        <QualificationsListView
          title="Company Stages"
          qualifications={companyStages}
          talentValues={talentCompanyStageValues}
          isOpen={open === "companyStage"}
          onToggleOpen={() =>
            setOpen(open === "companyStage" ? "" : "companyStage")
          }
          onRatingChange={onCompanyStageRatingChange}
          onCheckboxClick={onCompanyStageCheckboxClick}
          isUpdating={isUpdating}
          displayCount={displayCount}
          maxDisplayCount={10}
          ratingHelperLabel="Company Stage"
        />
      </div>
      <ActionBar
        displayActionBar={displayActionBar}
        isUpdating={isUpdating}
        onClickCancel={onClickCancel}
        onClickSave={onClickSave}
      />
      <PromptUpdater />
    </>
  );
};

export default Industries;
