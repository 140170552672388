import React from "react";

import clsx from "clsx";

/**
 * Renders a loading placeholder box/line.
 * @param {string} className - Classes to append to the default classes, used to set the size of the placeholder.
 */
const LoadPlaceholder = ({ className = "" }) => (
  <div className={clsx("bg-animated", className)} />
);

/**
 * Renders a common title and paragraph load placeholders.
 */
export const LoadPlaceholderGroup = () => (
  <>
    <LoadPlaceholder className="mb-6 h-4 w-60" />
    <LoadPlaceholder className="mb-2 h-4 w-60" />
    <LoadPlaceholder className="mb-2 h-4 w-40" />
    <LoadPlaceholder className="mb-2 h-4 w-32" />
  </>
);

export default LoadPlaceholder;
