import React from "react";
import clsx from "clsx";
import UilCamera from "@iconscout/react-unicons/icons/uil-camera-plus";
import UilPen from "@iconscout/react-unicons/icons/uil-pen";
import UilTrash from "@iconscout/react-unicons/icons/uil-trash-alt";
import UilPlus from "@iconscout/react-unicons/icons/uil-plus";
import UilDown from "@iconscout/react-unicons/icons/uil-angle-down";
import UilHide from "@iconscout/react-unicons/icons/uil-eye-slash";
import UilShow from "@iconscout/react-unicons/icons/uil-eye";
import UilUp from "@iconscout/react-unicons/icons/uil-angle-up";
import UilLeft from "@iconscout/react-unicons/icons/uil-angle-left-b";
import { Link } from "react-router-dom";
import UilTimes from "@iconscout/react-unicons/icons/uil-times";
import UilImport from "@iconscout/react-unicons/icons/uil-import";
import UilSync from "@iconscout/react-unicons/icons/uil-sync";
import UilCheck from "@iconscout/react-unicons/icons/uil-check-circle";
import UilSave from "@iconscout/react-unicons/icons/uil-save";
import { global as $ } from "strings";
import UilLink from "@iconscout/react-unicons/icons/uil-link";

/**
 * Renders a close button with a times icon. It is used in some preview panels.
 * @param {callback} onClick - A callback to trigger when clicked.
 */
const CloseBtn = ({ onClick }) => (
  <button onClick={onClick} className="focus:outline-none group h-8 w-8">
    <span
      className="focus:outline-none inline-flex h-8 w-8 items-center justify-center rounded text-lg text-cove hover:bg-link-water active:bg-geyser group-focus:ring"
      tabIndex="-1"
    >
      <UilTimes size="24" />
    </span>
  </button>
);

/**
 * Renders a toggler button. Used in to toggle filters.
 * @param {component} children - Children to render, probably just the button text.
 * @param {bool} active - Flag to indicate the button state.
 * @param {string} className - Classes to append to the button default classes.
 * @param {object} props - Extra props to set to the button.
 */
const Toggler = ({ children, active, className = "", ...props }) => (
  <button
    type={props.type || "button"}
    {...props}
    className={clsx("focus:outline-none group h-10", className)}
  >
    <span
      className={clsx(
        "focus:outline-none inline-flex h-10 items-center justify-center rounded bg-transparent px-4 text-sm font-bold text-midnight group-focus:ring",
        active ? "bg-link-water hover:bg-link-lighter" : "hover:bg-link-water"
      )}
      tabIndex="-1"
    >
      {children}
      {active && <UilUp size="16" />}
      {!active && <UilDown size="16" />}
    </span>
  </button>
);

/**
 * Renders a button. Used in most pages and forms. It supports different styles and states, like: danger, secondary, disabled and loading.
 * @param {component} children - Children to render, probably just the button text or an Icon.
 * @param {bool} secondary - Flag to style the button as a secondary.
 * @param {bool} danger - Flag to style the button in red.
 * @param {bool} disabled - Flag to indicate the button is disabled.
 * @param {bool} loading - Flag to indicate the button is waiting to load. It disables the button.
 * @param {string} className - Classes to append to the button default classes.
 * @param {object} props - Extra props to set to the button.
 */
const Button = ({
  children,
  secondary,
  danger,
  disabled,
  dropzone,
  loading,
  noMinW,
  className = "",
  ...props
}) => (
  <button
    type={props.type || "button"}
    {...props}
    disabled={disabled || loading}
    className={clsx(
      "focus:outline-none group flex h-10",
      noMinW ? "" : "min-w-36",
      className
    )}
  >
    <span
      className={clsx(
        "focus:outline-none inline-flex h-10 items-center justify-center rounded px-4 text-sm font-bold group-focus:ring",
        noMinW ? "" : "min-w-36",
        disabled
          ? "cursor-not-allowed bg-link-water text-kasmir"
          : danger
            ? "bg-red-dark text-white hover:bg-red-darker active:bg-red-darker"
            : secondary
              ? "bg-link-water text-link hover:bg-link-lighter active:bg-link-light"
              : dropzone
                ? "border-2 border-dashed border-white bg-red-dark text-white hover:bg-red-darker active:bg-red-darker"
                : loading
                  ? "bg-animated cursor-not-allowed text-kasmir"
                  : "bg-link text-white hover:bg-link-dark active:bg-link-darker",
        className
      )}
      tabIndex="-1"
    >
      {children}
    </span>
  </button>
);

const TransparentButton = ({
  children,
  disabled,
  loading,
  className = "",
  ...props
}) => (
  <button
    type={props.type || "button"}
    {...props}
    disabled={disabled || loading}
    className={clsx("focus:outline-none group h-10", className)}
  >
    <span
      className={clsx(
        "focus:outline-none inline-flex h-10 items-center justify-center rounded border-none bg-none px-0 text-sm font-bold text-kasmir hover:text-electric-indigo group-focus:ring",
        disabled
          ? "cursor-not-allowed bg-link-water text-kasmir"
          : loading
            ? "bg-animated cursor-not-allowed text-kasmir"
            : "",
        className
      )}
      tabIndex="-1"
    >
      {children}
    </span>
  </button>
);

const OutlinedButton = ({
  children,
  danger,
  secondary,
  className = "",
  ...props
}) => (
  <button
    type={props.type || "button"}
    {...props}
    className={clsx("focus:outline-none group h-6", className)}
  >
    <span
      className={clsx(
        "focus:outline-none inline-flex h-6 items-center justify-center rounded border bg-white px-2 text-sm font-bold group-focus:ring",
        danger ? "border-red-dark text-red-dark" : "",
        secondary ? "border-kasmir text-kasmir" : "",
        !danger && !secondary && "border-link text-link",
        props.disabled ? "border-opacity-50 text-opacity-50" : "",
        className
      )}
      tabIndex="-1"
    >
      {children}
    </span>
  </button>
);

const MergeButton = ({ children, className = "", ...props }) => (
  <Link
    {...props}
    className={clsx("focus:outline-none group h-6 min-w-36", className)}
  >
    <span
      className={clsx(
        "focus:outline-none inline-flex h-6 items-center justify-center rounded border border-green bg-white px-2 text-sm font-bold text-green group-focus:ring",
        className
      )}
      tabIndex="-1"
    >
      {children}
    </span>
  </Link>
);
const HubspotButton = ({ children, className = "", ...props }) => (
  <Link
    {...props}
    className={clsx("focus:outline-none group h-6 min-w-36", className)}
    target="_blank"
  >
    <span
      className={clsx(
        "focus:outline-none inline-flex h-6 items-center justify-center rounded border border-link bg-white px-2 text-sm font-bold text-link group-focus:ring",
        className
      )}
      tabIndex="-1"
    >
      {children}
    </span>
  </Link>
);

/**
 * Renders an anchor (link) as a button. Used in some links that design required to be a button.
 * It wrappers a react-router-dom Link component, so you can pass those same props.
 * @param {component} children - Children to render, probably just the button text or an Icon.
 * @param {bool} secondary - Flag to style the button as a secondary.
 * @param {bool} danger - Flag to style the button in red.
 * @param {string} className - Classes to append to the button default classes.
 * @param {object} props - Extra props to set to the button.
 */
const LinkButton = ({
  children,
  secondary,
  danger,
  className = "",
  ...props
}) => (
  <Link
    {...props}
    className={clsx("focus:outline-none group h-10 min-w-36", className)}
  >
    <span
      className={clsx(
        "focus:outline-none inline-flex h-10 min-w-36 items-center justify-center rounded px-4 text-sm font-bold group-focus:ring",
        secondary
          ? "bg-link-water text-link hover:bg-link-lighter active:bg-link-light"
          : danger
            ? "bg-red-dark text-white hover:bg-red-darker active:bg-red-darker"
            : props.disabled
              ? "bg-animated cursor-not-allowed text-kasmir"
              : "bg-link text-white hover:bg-link-dark active:bg-link-darker",
        className
      )}
      tabIndex="-1"
    >
      {children}
    </span>
  </Link>
);

/**
 * Renders a button with an icon. Used in some recurrent buttons like edit, remove or add ones.
 * @param {component} children - Children to render, an Icon.
 * @param {bool} colored - Flag to style the button with an accent color.
 * @param {object} props - Extra props to set to the button.
 */
const IconBtn = ({
  children,
  colored,
  fullColored,
  danger,
  className,
  ...props
}) => (
  <button
    type={props.type || "button"}
    {...props}
    className={clsx("focus:outline-none group ml-2 h-6 w-6", className)}
  >
    <span
      className={clsx(
        "focus:outline-none inline-flex h-6 w-6 items-center justify-center overflow-hidden rounded-full bg-lightest-grey text-sm hover:bg-alice-blue group-focus:ring",
        colored
          ? "text-link"
          : danger
            ? "bg-red-dark text-white hover:bg-red-darker active:bg-red-darker"
            : fullColored
              ? "bg-link text-white hover:bg-link-dark active:bg-link-darker"
              : "text-kasmir",
        className
      )}
      tabIndex="-1"
    >
      {children}
    </span>
  </button>
);

/**
 * Renders a button with a Left arrow. Used to show buttons to go back to another page.
 * It wrappers a react-router-dom Link component, so you can pass those same props.
 * @param {string} text - Text to render.
 * @param {string} to - Url to link.
 */
const BackLinkBtn = ({ text, to }) => (
  <Link
    to={to}
    className="inline-flex items-center rounded bg-link-lighter px-2 py-1 text-sm font-bold text-link hover:bg-link-light active:bg-link-light"
  >
    <UilLeft size="18" />
    {text}
  </Link>
);

/**
 * Renders a button with a camera icon.
 * @param {object} props - Extra props to set to the button.
 */
const PhotoBtn = (props) => (
  <IconBtn {...props}>
    <UilCamera size="16" />
  </IconBtn>
);

/**
 * Renders a button with a save icon.
 * @param {object} props - Extra props to set to the button.
 */
const SaveBtn = (props) => (
  <IconBtn {...props}>
    <UilSave size="16" />
  </IconBtn>
);

/**
 * Renders a button with a pen icon.
 * @param {object} props - Extra props to set to the button.
 */
const EditBtn = (props) => (
  <IconBtn {...props}>
    <UilPen size="20" />
  </IconBtn>
);

/**
 * Renders a button with a trash icon.
 * @param {object} props - Extra props to set to the button.
 */
const RemoveBtn = ({ className, ...props }) => (
  <IconBtn
    className={clsx(
      props.disabled ? "border-opacity-50 text-opacity-50" : "",
      className
    )}
    {...props}
  >
    <UilTrash size="16" />
  </IconBtn>
);

/**
 * Renders a button with a times icon.
 * @param {object} props - Extra props to set to the button.
 */
const CancelBtn = (props) => (
  <IconBtn {...props}>
    <UilTimes size="16" />
  </IconBtn>
);

/**
 * Renders a button with a plus icon.
 * @param {object} props - Extra props to set to the button.
 */
const AddBtn = (props) => (
  <IconBtn {...props}>
    <UilPlus size="24" />
  </IconBtn>
);

/**
 * Renders a button with a hide icon.
 * @param {bool} show
 * @param {object} props - Extra props to set to the button.
 */
const HideBtn = ({ show, ...props }) => (
  <IconBtn {...props}>
    {show ? <UilShow size="18" /> : <UilHide size="18" />}
  </IconBtn>
);

/**
 * Renders a button with a sync icon.
 * @param {object} props - Extra props to set to the button.
 */
const RetryBtn = (props) => (
  <IconBtn {...props}>
    <UilSync size="18" />
  </IconBtn>
);

/**
 * Renders a link with a download icon.
 * @param {bool} colored - Flag to style the button with an accent color.
 * @param {object} props - Extra props to set to the button.
 */
const DownloadBtn = ({ colored, ...props }) => (
  <a {...props} className="focus:outline-none group ml-2 h-6 w-6">
    <span
      className={clsx(
        "focus:outline-none inline-flex h-6 w-6 items-center justify-center overflow-hidden rounded bg-link-water text-sm hover:bg-link-light active:bg-link-light group-focus:ring",
        props.colored ? "text-link" : "text-kasmir"
      )}
      tabIndex="-1"
    >
      <UilImport size="18" />
    </span>
  </a>
);

/**
 * Renders a link with a link icon.
 * @param {bool} colored - Flag to style the button with an accent color.
 * @param {object} props - Extra props to set to the button.
 */
const AnchorBtn = ({ colored, ...props }) => (
  <a {...props} className="focus:outline-none group ml-2 h-6 w-6">
    <span
      className={clsx(
        "focus:outline-none inline-flex h-6 w-6 items-center justify-center overflow-hidden rounded bg-link-water text-sm hover:bg-link-light active:bg-link-light group-focus:ring",
        props.colored ? "text-link" : "text-kasmir"
      )}
      tabIndex="-1"
    >
      <UilLink size="18" />
    </span>
  </a>
);

/**
 * Renders a button with required text.
 * @param {object} props - Extra props to set to the button.
 */
const RequiredBtn = ({ colored, ...props }) => (
  <button
    type={props.type || "button"}
    {...props}
    className="focus:outline-none group ml-2 h-6"
  >
    <span
      className={clsx(
        "focus:outline-none inline-flex h-6 items-center justify-center overflow-hidden rounded bg-link-water px-2 text-sm hover:bg-link-light active:bg-link-light group-focus:ring",
        colored ? "font-bold text-link" : "text-kasmir"
      )}
      tabIndex="-1"
    >
      {$.required_button}
    </span>
  </button>
);

/**
 * Renders a button with a check. Used in some parts to indicate an item is selected.
 * @param {object} props - Extra props to set to the button.
 */
const SelectedBtn = (props) => (
  <button
    type="button"
    {...props}
    className="focus:outline-none group ml-2 h-6 w-6"
  >
    <span
      className="focus:outline-none inline-flex h-6 w-6 items-center justify-center overflow-hidden rounded bg-transparent text-sm text-link hover:bg-link-water active:bg-link-water group-focus:ring"
      tabIndex="-1"
    >
      <UilCheck className="text-link" size="20" />
    </span>
  </button>
);

const CloseXButton = ({ onClick, className }) => (
  <button
    onClick={onClick}
    className={`focus:outline-none group h-8 w-8 ${className}`}
  >
    <span
      className="focus:outline-none inline-flex h-8 w-8 items-center justify-center rounded text-lg text-cove group-focus:ring"
      tabIndex="-1"
    >
      <UilTimes size="24" />
    </span>
  </button>
);

export {
  Button,
  PhotoBtn,
  EditBtn,
  RemoveBtn,
  AddBtn,
  HideBtn,
  RequiredBtn,
  Toggler,
  BackLinkBtn,
  LinkButton,
  CloseBtn,
  DownloadBtn,
  CancelBtn,
  SaveBtn,
  RetryBtn,
  SelectedBtn,
  IconBtn,
  OutlinedButton,
  AnchorBtn,
  MergeButton,
  HubspotButton,
  TransparentButton,
  CloseXButton
};
