import React from "react";
import loginArt from "../../../assets/login_art.png";
import logoSm from "../../../assets/logo_sm.svg";

/**
 * This is the layout that our auth pages use. In desktop screens
 * it shows a centered form, with content on the left, and placeholder
 * art on the right. In mobile screens, the elements are stacked.
 *
 * This is pulled out into a component so that we can reuse it across all
 * 6 screens.
 */
const AuthLayout = ({ children }) => {
  return (
    <div className="flex items-center justify-center bg-alice-blue p-4 pb-12 font-lato text-dark-navy md:h-screen md:w-screen md:px-24 md:py-12">
      <div className="flex md:relative md:h-full md:w-300">
        <div className="flex h-full max-w-78 flex-col rounded-2xl bg-white md:max-w-full md:flex-row">
          <div className="relative flex flex-1 flex-col md:h-full">
            <div className="p-4 md:p-8">
              <img className="h-10 w-10" src={logoSm} alt="Right Side Up" />
            </div>
            <div className="flex flex-1 flex-col items-center justify-center px-4 pb-6 md:px-8 md:pb-24">
              {children}
            </div>
          </div>
          <div className="flex flex-1 flex-col justify-between bg-lightest-grey text-center md:h-full md:rounded-r-2xl">
            <div className="mt-20 flex h-1/4 flex-col gap-y-3 px-8">
              <h1 className="text-2.5xl font-bold">Be your own boss, boss</h1>
              <p className="text-sm">
                Deliver excellent work that thrills clients by taking on
                projects that light you up. Whether you want to moonlight or
                freelance full time, you dictate who you work with, how many
                hours you work, and how much you get.
              </p>
            </div>
            <div className="flex h-full items-end">
              <img className="" src={loginArt} alt="rsu art 1" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuthLayout;
