import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { useParams } from "react-router-dom";
import { admin_talent_overview as $ } from "strings";
import { Prompt } from "react-router-dom";
import Select from "components/CompactSelect";
import RichTextEditor from "components/RichTextEditorNew";
import UilArrow from "@iconscout/react-unicons/icons/uil-arrow-circle-right";
import { isBodyEmpty } from "components/RichTextEditor";
import { getAdminUser } from "utils/localStorageService";
import AlertError from "components/AlertError";
import { useAlert } from "react-alert";
import { serialize } from "utils/htmlSerializer";
import { postTalentNote } from "utils/adminApi";
import { usePageCache } from "contexts/pageCacheContext";
import useConfirmClose from "hooks/useConfirmClose";

const optionStyles = {
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected
      ? "#dce9f6"
      : state.isFocused
        ? "#f4f6f9"
        : "#fff",
    fontFamily: "Lato",
    fontWeight: state.isSelected ? "500" : "400",
    fontSize: ".75rem",
    color: state.isDisabled
      ? "#a8b7c7"
      : state.isSelected
        ? "#0070d2"
        : "#54698d",
    "&:hover": {
      backgroundColor: state.isSelected ? "#dce9f6" : "#f4f6f9"
    }
  })
};

const TEMPLATE_OPTIONS = [
  { label: "Phone Screen Template", value: "phone" },
  { label: "Tech Screen Template", value: "tech" }
];

const getPhoneTemplate = () => {
  const template = [
    { type: "heading-two", children: [{ text: "Talent Team Phone Screen" }] }
  ];
  $.phone_template.forEach((i) => {
    template.push({
      type: "bulleted-list",
      children: [
        { text: i, bold: true },
        { text: ":", bold: false }
      ]
    });
  });

  return template;
};
const getTechScreenTemplate = () => {
  const template = [
    { type: "heading-two", children: [{ text: "Tech Screen Notes" }] }
  ];
  $.tech_screen_template.forEach((i) => {
    template.push({
      type: "bulleted-list",
      children: [
        { text: i, bold: true },
        { text: ":", bold: false }
      ]
    });
  });

  return template;
};

const NoteForm = ({ data, addNote }) => {
  const user = getAdminUser();
  const { id } = useParams();
  const alert = useAlert();
  const [saving, setSaving] = useState();
  const { set, cache } = usePageCache();
  const [selectedTemplate, setSelectedTemplate] = useState();
  const [values, setValues] = useState(
    cache["admin_project_overview_note_draft__" + id] || {
      body: [{ type: "paragraph", children: [{ text: "" }] }]
    }
  );

  const updateVal = (k, v) => {
    setValues((vs) => ({ ...vs, [k]: v }));
  };

  const isEmpty = isBodyEmpty(values.body);
  const { canClose, confirmMsg } = useConfirmClose(!isEmpty);

  useEffect(() => {
    if (id) {
      set("admin_project_overview_note_draft__" + id, values);
    }
  }, [values, id]);

  const onSubmit = async () => {
    setSaving(true);

    let body = isEmpty ? [] : values.body;

    const toSend = {
      body: serialize({ children: body }),
      talent: id,
      created_by_id: user.id,
      created_by: user.first_name + " " + user.last_name
    };

    const call = async () => {
      try {
        const n = await postTalentNote(toSend);
        setValues({ body: [{ type: "paragraph", children: [{ text: "" }] }] });
        addNote({
          note_body: n.body,
          created_by_name: n.created_by,
          id: n.id,
          created_timestamp: n.created_on,
          updated_at: n.updated_at,
          updated_by_id: n.created_by_id,
          talent_ref: n.talent
        });

        set("admin_talent_overview_note_draft__" + id, null);
        setSelectedTemplate();
      } catch (e) {
        console.error(e);
        alert.error(<AlertError error={e} />);
      }
      setSaving(false);
    };

    await call();
  };

  const onChangeTemplate = (template) => {
    setSelectedTemplate(template);
    if (template.value === "phone") {
      updateVal(
        "body",
        isEmpty ? getPhoneTemplate() : [...values.body, ...getPhoneTemplate()]
      );
    } else if (template.value === "tech") {
      updateVal(
        "body",
        isEmpty
          ? getTechScreenTemplate()
          : [...values.body, ...getTechScreenTemplate()]
      );
    }
  };

  return (
    <>
      <Prompt when={!isEmpty} message={confirmMsg} />
      <RichTextEditor
        placeholder={`Save Information to ${data?.first_name} ${data?.last_name}`}
        value={values.body}
        onChange={(v) => updateVal("body", v)}
        isStageChangeNote={false}
      />
      <div className="flex items-center justify-start rounded-b border-b border-l border-r border-geyser px-2 pb-1">
        <div className="flex flex-1 items-center justify-start">
          <Select
            className="w-48"
            customStyles={optionStyles}
            value={
              selectedTemplate || { label: "Select Note Template", value: null }
            }
            options={TEMPLATE_OPTIONS}
            onChange={(v) => onChangeTemplate(v)}
          />
        </div>
        <div className="flex flex-1 items-center justify-end">
          {!isEmpty && (
            <button
              onClick={() => {
                if (canClose()) {
                  updateVal("body", [
                    { type: "paragraph", children: [{ text: "" }] }
                  ]);
                  setValues({
                    body: [{ type: "paragraph", children: [{ text: "" }] }]
                  });
                  set("admin_talent_overview_note_draft__" + id, null);
                  setSelectedTemplate();
                }
              }}
              type="button"
              className="flex items-center text-sm font-bold text-kasmir"
            >
              {$.cancel_btn}
            </button>
          )}
          <button
            onClick={onSubmit}
            type="button"
            className={clsx(
              "ml-4 flex items-center text-sm font-bold",
              saving || isEmpty ? "cursor-not-allowed text-caded" : "text-link"
            )}
            disabled={saving || isEmpty}
          >
            {saving ? $.saving_btn : $.submit_btn}
            <UilArrow size="20" className="ml-1" />
          </button>
        </div>
      </div>
    </>
  );
};

export default NoteForm;
