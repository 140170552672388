import React from "react";
import clsx from "clsx";
import UilDown from "@iconscout/react-unicons/icons/uil-angle-down";
import UilUp from "@iconscout/react-unicons/icons/uil-angle-up";

/**
 * A NavItem corresponds to a single item in the settings navigation, which
 * can have subsection.
 *
 * The component displays and icon, text, and the subsections if the item is
 * active.
 */
const NavItem = ({
  title,
  onClick,
  isActive,
  subsections,
  activeIcon,
  icon
}) => {
  return (
    <div className={clsx("flex w-full flex-col justify-center")}>
      <div
        className={clsx(
          "flex cursor-pointer items-center px-3 py-3.5",
          isActive && !subsections && "rounded-lg bg-lightest-grey",
          isActive ? "text-electric-indigo" : "text-midnight"
        )}
        onClick={onClick}
      >
        <div className="h-full pr-2">{isActive ? activeIcon : icon}</div>
        <div className={clsx("ml-2 text-base font-semibold")}>{title}</div>
        {subsections && (
          <div className="ml-auto">
            {isActive ? <UilUp size="20" /> : <UilDown size="20" />}
          </div>
        )}
      </div>
      {isActive && subsections && (
        <div className="mx-2 flex flex-col">{subsections}</div>
      )}
    </div>
  );
};

/**
 * A NavSubsectionItem corresponds to a nested item of a NavItem.
 */
const NavSubsectionItem = ({ title, onClick, isActive }) => {
  return (
    <div key={title} className="cursor-pointer" onClick={onClick}>
      <div
        className={clsx(
          "ml-8 flex py-2 text-sm font-medium text-kasmir",
          isActive && "rounded-lg bg-lightest-grey text-electric-indigo"
        )}
      >
        <div className="pl-2">{title}</div>
      </div>
    </div>
  );
};

/**
 * Renders a rounded component that contains the Account Settings navigation.
 * The exact screens and subsections are defined in the screensMap object.
 */
export const SidebarNavSection = ({
  title,
  screensMap,
  activeScreen,
  activeSubsection,
  onClick,
  onSubsectionClick
}) => {
  return (
    <div className="flex-col gap-4 rounded-xl bg-white p-3.5 md:p-5">
      <div className="pb-3 text-2xl font-bold text-midnight">{title}</div>
      <div className="flex flex-col gap-1 md:gap-4">
        {Object.keys(screensMap).map((screen) => (
          <NavItem
            key={screen}
            title={screensMap[screen].title}
            onClick={(e) => onClick(screen)}
            isActive={activeScreen === screen}
            activeIcon={screensMap[screen].activeIcon}
            icon={screensMap[screen].icon}
            subsections={
              screensMap[screen].subsections &&
              Object.keys(screensMap[screen].subsections).map((subsection) => (
                <NavSubsectionItem
                  key={subsection}
                  title={screensMap[screen].subsections[subsection].title}
                  onClick={() => onSubsectionClick(subsection)}
                  isActive={activeSubsection === subsection}
                />
              ))
            }
          />
        ))}
      </div>
    </div>
  );
};
