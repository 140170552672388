import React, { useEffect, useState } from "react";
import $ from "strings/talent";
import { NavLink, useHistory } from "react-router-dom";
import { withHttp } from "utils/str";
import Logo from "components/Logo";
import userImg from "assets/user.svg";

import UilDown from "@iconscout/react-unicons/icons/uil-angle-down";
import UilUp from "@iconscout/react-unicons/icons/uil-angle-up";
import {
  clearLoginToken,
  getAccessToken, getImpersonatorAdminToken,
  getUrlSuffix
} from "../../../utils/localStorageService";
import { ArrowRight, Menu } from "react-feather";
import { Button } from "../components";
import WorkWithForm from "../Profile/WorkWithForm";
import EndImpersonationButton from "./EndImpersonationButton";

const RESOURCES_URL =
  "https://learn.rightsideup.com/contractor-resources/?utm_medium=product&utm_source=direct&utm_campaign=talent_profile&utm_content=talent&utm_term=talent_profile";
const TIMESHEETS_URL = "https://app.clockify.me/tracker";

const getNavTextColor = (path) => {
  return window.location.pathname.includes(path)
    ? "text-electric-indigo"
    : "text-nav-blue";
};

const MobileNavDrawer = ({
  userSuffix,
  onClose,
  displayName,
  profilePicture
}) => {
  // Close the drawer once we have clicked on something.
  useEffect(() => {
    const handleClick = (e) => {
      onClose();
    };

    // Use mousedown instead of click to handle the event before the modal
    window.addEventListener("click", handleClick);

    // Clean up the event listener when component unmounts
    return () => {
      window.removeEventListener("click", handleClick);
    };
  }, [onClose]);

  return (
    <>
      {/* Add overlay */}
      <div
        className="fixed inset-0 z-40 bg-black bg-opacity-10"
        onClick={onClose}
      />
      {/* Increase z-index of drawer to be above overlay */}
      <div className="fixed inset-y-0 right-0 z-50 h-screen w-64 flex-col items-start gap-4 rounded-l-lg bg-white p-5 md:hidden">
        {/** NavBar */}
        <div className="flex h-full flex-1 flex-col">
          <div className="flex items-center justify-between">
            <Logo className="h-10 w-10" />
            <div
              onClick={onClose}
              className="flex h-8 w-8 cursor-pointer items-center justify-center rounded-full bg-lightest-grey"
            >
              <ArrowRight />
            </div>
          </div>

          {/** Menu Section 1 */}
          <div className="flex flex-col gap-2.5 py-4 text-kasmir">
            <NavLink
              to={`/talent/${userSuffix}/expertise`}
              className="text-kasmir"
            >
              {$.expertise_navlink}
            </NavLink>
            <NavLink to={{ pathname: withHttp(RESOURCES_URL) }} target="_blank">
              {$.resource_navlink}
            </NavLink>
            <NavLink
              to={{ pathname: withHttp(TIMESHEETS_URL) }}
              target="_blank"
            >
              {$.timesheets_navlink}
            </NavLink>
          </div>

          {/** Menu Section 2 */}
          <div className="border-light-grey flex flex-col gap-2.5 border-t-2 py-4 text-kasmir">
            <NavLink to={`/talent/${userSuffix}/profile`}>
              {$.profile_navlink}
            </NavLink>
            <NavLink to={`/talent/${userSuffix}/settings`}>
              {$.settings_navlink}
            </NavLink>
            <NavLink to={`/talent/${userSuffix}/support`}>
              {$.support_navlink}
            </NavLink>
            <NavLink to={`/login`}>{$.logout_navlink}</NavLink>
          </div>

          {/** User indicator at bottom */}
          <div className="mt-auto flex h-12 flex-row items-center rounded-xl bg-lightest-grey p-2">
            <img
              src={profilePicture}
              className="mr-2 h-full w-auto rounded-full"
              alt={`${displayName}`}
            />
            <p className="font-semibold text-midnight">{displayName}</p>
          </div>
        </div>
      </div>
    </>
  );
};

export const NavBar = ({ talentData, isPublicProfile }) => {
  const [logged, setLogged] = useState(!!getAccessToken());
  const [isUserMenuOpen, setUserMenuOpen] = useState(false);
  const [isMobileDrawerOpen, setMobileDrawerOpen] = useState(false);
  const [isShowingWorkWithModal, setShowingWorkWithModal] = useState(false);
  const history = useHistory();
  const {
    user: { first_name, last_name, preferred_name, profile_photo }
  } = talentData;

  const userSuffix = getUrlSuffix();
  const isImpersonating = !!getImpersonatorAdminToken();

  const showNavigationLinks = !isPublicProfile && logged;

  useEffect(() => {
    const handleClick = (e) => {
      if (isUserMenuOpen) {
        setUserMenuOpen(false);
      }
    };
    window.addEventListener("click", handleClick);

    return () => {
      window.removeEventListener("click", handleClick);
    };
  }, [isUserMenuOpen]);

  return (
    <div className="mx-4 mt-4 flex h-13 select-none items-center justify-between rounded-large bg-white px-3.5 py-2.5">
      <div
        className="mr-8 flex h-full cursor-pointer items-center"
        onClick={() => history.push("/talent")}
      >
        <Logo />
      </div>

      {/* Public Profile.*/}
      {isPublicProfile && (
        <>
          <div className="flex md:hidden">
            <Button
              noMinW={true}
              onClick={() => {
                setShowingWorkWithModal(true);
              }}
            >
              {$.work_with_btn} {preferred_name || first_name}
            </Button>
          </div>
        </>
      )}

      {/* Desktop Navbar */}
      {showNavigationLinks && (
        <div className="hidden h-full w-full items-center justify-between md:flex">
          <div className="min-w-0 flex-1 items-center">
            <div
              className="mx-0 my-auto flex flex-1 gap-4 text-base font-bold text-nav-blue">
              <NavLink
                to={`/talent/${userSuffix}/profile`}
                className={getNavTextColor("profile")}
              >
                {$.profile_navlink}
              </NavLink>
              <NavLink
                to={`/talent/${userSuffix}/expertise`}
                className={getNavTextColor("expertise")}
              >
                {$.expertise_navlink}
              </NavLink>
              <NavLink
                to={{pathname: withHttp(RESOURCES_URL)}}
                target="_blank"
              >
                {$.resource_navlink}
              </NavLink>
              <NavLink
                to={{pathname: withHttp(TIMESHEETS_URL)}}
                target="_blank"
              >
                {$.timesheets_navlink}
              </NavLink>
              {isImpersonating && <EndImpersonationButton />}
            </div>
          </div>
          <div
            className="ml-auto flex h-full cursor-pointer items-center"
            onClick={(e) => {
              e.stopPropagation();
              setUserMenuOpen(!isUserMenuOpen);
            }}
          >
            <img
              src={profile_photo || userImg}
              className="mr-1.25 h-full w-auto rounded-full"
              alt={`${first_name} ${last_name}`}
            />
            {isUserMenuOpen ? <UilUp size="20" /> : <UilDown size="20" />}
            {isUserMenuOpen ? (
              <div className="absolute right-8 top-14 z-10 flex cursor-pointer flex-col rounded-md border border-lightest-grey bg-white p-2 text-sm text-nav-blue">
                <NavLink
                  className="rounded-sm p-1 hover:bg-link-water"
                  to={`/talent/${userSuffix}/settings`}
                >
                  {$.settings_navlink}
                </NavLink>
                <a
                  className="rounded-sm p-1 hover:bg-link-water"
                  href="https://rightsideup.slite.page/p/8oZZhIIjSmmEoH/Talent-Knowledge-Portal"
                  target="_blank"
                  rel="noreferrer"
                >
                  {$.support_navlink}
                </a>
                <NavLink
                  className="rounded-sm p-1 hover:bg-link-water"
                  onClick={() => {
                    clearLoginToken();
                    window.location.href = "/login"; // requires full page reload
                  }}
                  to={`/login`}
                >
                  {$.logout_navlink}
                </NavLink>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      )}

      {/* Mobile Navbar */}
      {showNavigationLinks && (
        <div className="flex h-full w-full cursor-pointer items-center justify-end md:hidden">
          <Menu onClick={() => setMobileDrawerOpen(!isMobileDrawerOpen)} />
        </div>
      )}

      {/* Mobile Drawer */}
      {isMobileDrawerOpen && showNavigationLinks && (
        <MobileNavDrawer
          userSuffix={userSuffix}
          onClose={() => setMobileDrawerOpen(false)}
          profilePicture={profile_photo || userImg}
          displayName={`${preferred_name || first_name} ${last_name}`}
        />
      )}

      {isShowingWorkWithModal && (
        <WorkWithForm
          onClose={() => setShowingWorkWithModal(false)}
          talentData={talentData}
        />
      )}
    </div>
  );
};
