import React from "react";
import clsx from "clsx";
import RatingInput from "./RatingInput";
import { Checkbox } from "components/Form";

const Qualification = ({
  qualification,
  rating,
  onRatingChange,
  disabled,
  onCheckboxClick,
  showCheckbox,
  checkboxDisabled,
  checked,
  backgroundColor = "bg-lightest-grey"
}) => {
  const { name, id } = qualification;

  return (
    <div
      className={clsx(
        "space-between grid w-full grid-cols-5 items-center rounded-xl p-2 text-sm font-medium",
        showCheckbox ? "sm:grid-cols-6" : "",
        backgroundColor
      )}
    >
      <div className="col-span-2 sm:col-span-3">{name}</div>
      <div
        className={clsx(
          "col-span-3 sm:col-span-2",
          showCheckbox ? "ml-auto flex-1 sm:ml-0" : "ml-auto mr-0"
        )}
      >
        <RatingInput
          value={rating}
          onChange={(v) => onRatingChange(id, v, qualification)}
          disabled={disabled}
        />
      </div>
      {showCheckbox && (
        <div className="col-span-3 mr-0 mt-1 flex flex-row sm:col-span-1 sm:ml-auto sm:mt-0">
          <Checkbox
            id={`checkbox--${id}`}
            outlined
            disabled={checkboxDisabled}
            value={checked}
            onChange={(v) => onCheckboxClick(v)}
          />
          <div className="text-mini text-kasmir sm:hidden">
            Display on profile page
          </div>
        </div>
      )}
    </div>
  );
};

export default Qualification;
