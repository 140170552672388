import React, { useState } from "react";
import $ from "strings/talent";
import { Input } from "components/Form";
import { Label } from "../components";
import { ChangePasswordModal } from "./ChangePasswordModal";

const LoginCredentials = ({ talentData, onUpdate }) => {
  const [showPasswordChangeModal, setShowPasswordChangeModal] = useState(false);

  return (
    <>
      {showPasswordChangeModal && (
        <ChangePasswordModal
          talentData={talentData}
          onUpdate={onUpdate}
          onClose={() => setShowPasswordChangeModal(false)}
        />
      )}
      <div className="flex flex-row">
        <div className="flex-col md:mr-4 md:w-1/2">
          <Label>{$.email_label}</Label>
          <Input
            name="firstName"
            value={talentData?.user?.email || ""}
            disabled
          />
          <p className="mt-2 text-sm text-gray-500">
            Please contact&nbsp;
            <a
              className="text-electric-indigo underline"
              href="mailto:support@rightsideup.co"
            >
              support@rightsideup.co
            </a>
            &nbsp;to change your login email
          </p>
          <button
            className="mt-4 text-electric-indigo underline"
            onClick={() => setShowPasswordChangeModal(true)}
          >
            Change password
          </button>
        </div>
      </div>
    </>
  );
};

export default LoginCredentials;
