import React, { useEffect, useState } from "react";
import { admin_login as $ } from "strings";
import { resetPasswordConfirm } from "utils/api";
import { useAlert } from "react-alert";
import AlertError from "components/AlertError";
import { Input } from "../../components/Form";
import { useHistory } from "react-router-dom";
import { Button } from "./components";
import AuthLayout from "./components/AuthLayout";

const ResetPasswordConfirm = ({ setLogged }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  useEffect(() => {
    document.title = "Password Reset";
    const urlParams = new URLSearchParams(window.location.search);
    const email = urlParams.get("email");
    setEmail(email);
  }, []);

  const alert = useAlert();
  const history = useHistory();

  const handleResetPasswordConfirm = async () => {
    try {
      const urlParams = new URLSearchParams(window.location.search);
      const userId = urlParams.get("user");
      const token = urlParams.get("token");
      if (!userId || !token) {
        alert.error("user and token are required");
        return;
      }
      await resetPasswordConfirm(userId, token, { password });
      alert.success("Password reset successful");
      await new Promise((r) => setTimeout(r, 2000));
      history.push(`/login`);
    } catch (e) {
      console.error(e);
      alert.error(<AlertError error={e} message={$.auth_error} />);
    }
  };

  return (
    <AuthLayout>
      <div className="flex h-full flex-col items-center justify-center">
        <h1 className="mb-4 text-2xl font-semibold">Set your new password</h1>
        <label className="mb-1 w-full text-left text-sm">Enter Password</label>
        <div className="mb-3 w-full">
          <Input
            type="password"
            fontSize="text-sm"
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <label className="mb-1 w-full text-left text-sm">
          Confirm Password
        </label>
        <div className="mb-4 w-full">
          <Input
            type="password"
            fontSize="text-sm"
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
        </div>
        <Button
          className="w-full"
          disabled={
            !password || !confirmPassword || password !== confirmPassword
          }
          onClick={handleResetPasswordConfirm}
          large
        >
          Confirm
        </Button>
        <p className="mb-4 mt-4 w-full text-center text-sm text-kasmir">
          Having trouble logging in?{" "}
          <a
            href="mailto:talent@rightsideup.co"
            className="text-link underline hover:text-link-dark"
          >
            Contact support
          </a>
        </p>
      </div>
    </AuthLayout>
  );
};

export default ResetPasswordConfirm;
