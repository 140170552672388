import React, { useState, useEffect } from "react";

import clsx from "clsx";

/**
 * Renders an editable text. When clicked it shows an input, and on unBlur shows the text back again.
 * It is used internally in the Range component.
 */
const Editable = ({
  text,
  defaultValue,
  error,
  onChange,
  inputClass = "",
  inputProps,
  type,
  fullWidth,
  dynamicHeight,
  disabled,
  ...props
}) => {
  const [isEditing, setEditing] = useState(false);
  const [value, setValue] = useState();

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  const handleKeyDown = (e) => {
    const { key } = e;
    const keys = ["Escape", "Tab", "Enter"];

    if (keys.indexOf(key) > -1) {
      setEditing(false);
      onChange(value);
    }
  };

  return (
    <div className={fullWidth && "w-full"} {...props}>
      {isEditing ? (
        <div
          onBlur={() => {
            onChange(value);
            setEditing(false);
          }}
          onKeyDown={(e) => handleKeyDown(e)}
        >
          <input
            onChange={(e) => setValue(e.target.value)}
            value={value}
            autoFocus
            type={type}
            className={clsx(
              inputClass,
              "placeholder-text-kasmir focus:outline-none h-5 appearance-none rounded border border-link-water bg-white px-0 py-0 text-left text-sm font-medium placeholder:font-medium",
              fullWidth ? "w-full" : type === "number" ? "w-8" : "w-28"
            )}
          />
        </div>
      ) : (
        <div
          onClick={() => {
            if (!disabled) {
              setEditing(true);
              setValue(defaultValue);
            }
          }}
          className={clsx(
            "cursor-default truncate text-sm",
            !dynamicHeight ? "h-5" : "",
            !defaultValue || defaultValue === 0
              ? "font-medium text-kasmir"
              : "font-bold text-midnight"
          )}
        >
          {text}
        </div>
      )}
    </div>
  );
};

export default Editable;
