import React from "react";
import clsx from "clsx";
import { B2DB, B1 } from "./Typography";
import UilDirection from "@iconscout/react-unicons/icons/uil-direction";
import UilUp from "@iconscout/react-unicons/icons/uil-angle-up";
import UilDown from "@iconscout/react-unicons/icons/uil-angle-down";

const Table = ({ children }) => (
  <table
    className="table min-w-full table-auto border-separate rounded-t text-left"
    style={{ borderSpacing: 0 }}
  >
    {children}
  </table>
);

const Head = ({ children }) => (
  <thead className="rounded-t bg-link-water">
    <tr className="">{children}</tr>
  </thead>
);

const H = ({
  children,
  sorted,
  direction,
  disableSort,
  onSort,
  dragOver,
  className = "",
  ...props
}) => (
  <th
    {...props}
    className={clsx(
      "sticky top-0 bg-link-water py-3 uppercase",
      className,
      dragOver ? "bg-geyser" : ""
    )}
  >
    <div className="flex w-full items-center px-6">
      <B2DB>{children}</B2DB>
      {onSort && !disableSort && (
        <button
          onClick={onSort}
          className="focus:outline-none group ml-1 h-6 w-6"
        >
          <span
            className={clsx(
              "focus:outline-none flex h-6 w-6 appearance-none items-center justify-center rounded active:bg-geyser group-focus:ring",
              sorted
                ? "text-link hover:text-link-dark"
                : "text-geyser hover:text-kasmir"
            )}
            tabIndex="-1"
          >
            {!sorted && <UilDirection size="22" />}
            {sorted && direction === "-" && <UilUp size="22" />}
            {sorted && direction === "" && <UilDown size="22" />}
          </span>
        </button>
      )}
    </div>
  </th>
);

const Body = ({ children, className, ...props }) => (
  <tbody {...props}>{children}</tbody>
);

const Footer = ({ children }) => (
  <div className="rounded-b border border-geyser px-6 py-2">
    <B1>{children}</B1>
  </div>
);

const D = ({
  children,
  className = "",
  dragOver,
  hideLeftBorder = false,
  ...props
}) => (
  <td
    {...props}
    className={clsx(
      "border-b border-geyser px-6 py-2 text-sm font-medium text-kasmir last:border-r",
      className,
      dragOver ? "bg-geyser" : "",
      hideLeftBorder ? "" : "first:border-l"
    )}
  >
    {children}
  </td>
);

const Row = ({ children, ...props }) => <tr {...props}>{children}</tr>;

export { Table, Head, Body, Footer, H, D, Row };
