import React, { useState } from "react";
import clsx from "clsx";
import { admin_talent_project as $ } from "strings";
import { deleteTalentNoteV2, deleteStatusNote } from "utils/adminApi";
import { toFormat } from "utils/date";
import { statusOptions } from "./constants";
import AlertError from "components/AlertError";
import EditCandidateActivityForm from "./EditCandidateActivityForm";
import { urlify } from "utils/str";

const CandidateActivityItem = ({
  note,
  editable,
  updateNote,
  displayStatus,
  measureRef,
  removeNote,
  className
}) => {
  const [editing, setEditing] = useState();

  const { note_body, status, updated_at, updated_by_name } = note;

  const containerRef = React.useRef();

  const onDelete = async () => {
    const call = async () => {
      removeNote(note.id);

      try {
        if (note.type === "status_history") {
          await deleteStatusNote(note.id);
        } else {
          await deleteTalentNoteV2(note.talent_ref, note.type, note.id);
        }
      } catch (e) {
        console.error(e);
        alert.error(<AlertError error={e} />);
      }
    };

    await call();
  };

  return (
    <div className={clsx("py-1", className)}>
      {!editing && (
        <div>
          <div ref={measureRef} className="text-sm font-bold text-midnight">
            {status && displayStatus && (
              <span>
                {$.changed_status_label}{" "}
                {statusOptions.find((o) => o.value === status).label}.
              </span>
            )}
          </div>
          <div className="rich-content relative min-h-0 whitespace-pre-line text-sm font-bold text-midnight">
            {note_body && (
              <span className="text-sm font-bold text-midnight">
                {updated_by_name || $.unknown_author_label} {$.added_by_label}:
              </span>
            )}
            {note_body && (
              <div
                className="text-sm font-normal"
                dangerouslySetInnerHTML={{ __html: urlify(note_body) }}
              />
            )}
          </div>
          <div className="flex items-center justify-start text-xs text-kasmir">
            <div className="group relative flex items-center">
              {updated_at && (
                <div className="">
                  {toFormat(new Date(updated_at), "MMMM dd, yyyy 'at' h:mm a")}
                </div>
              )}
              {note.created_timestamp &&
                (editable ? <span className="mx-1">|</span> : "")}
            </div>
            {editable && (
              <>
                <button
                  className="focus:outline-none outline-none p-0 text-xs text-kasmir"
                  onClick={() => {
                    setEditing(true);
                  }}
                >
                  {$.edit_btn}
                </button>
                <span className="mx-1">|</span>
                <button
                  className="focus:outline-none outline-none p-0 text-xs text-kasmir"
                  onClick={onDelete}
                >
                  {$.delete_btn}
                </button>
              </>
            )}
          </div>
        </div>
      )}
      {editing && (
        <div>
          <div className="text-sm font-bold text-midnight">
            {note.updated_by_name === "Crelate_Populate_Talents_Script"
              ? "Crelate Tags"
              : note.updated_by_name || $.no_author_label}{" "}
            {$.added_by_label}
          </div>
          <EditCandidateActivityForm
            note={note}
            updateNote={updateNote}
            setEditing={setEditing}
          />
        </div>
      )}
    </div>
  );
};

export default CandidateActivityItem;
