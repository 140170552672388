import React from "react";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { admin_talent_project as $ } from "strings";
import { toFormat } from "utils/date";
import Header from "components/ListHeader";
import UilUserCircle from "@iconscout/react-unicons/icons/uil-user-circle";
import UilFile from "@iconscout/react-unicons/icons/uil-file-alt";
import UilCalendar from "@iconscout/react-unicons/icons/uil-calender";
import UilCalendarAlt from "@iconscout/react-unicons/icons/uil-calendar-alt";
import UilBriefcase from "@iconscout/react-unicons/icons/uil-briefcase";
import UilHeartRate from "@iconscout/react-unicons/icons/uil-heart-rate";
import { statusOptions } from "./constants";
import { stageOptions } from "utils/globalConsts";

const ProjectListItem = ({ project, onSelectProject, clickable }) => {
  const {
    created_at,
    created_by_name,
    id,
    project_id,
    project_last_updated,
    project_name,
    project_stage,
    status
  } = project;

  const handleClick = (e) => {
    if (!clickable) return;
    if (!e.target.id.includes("projectLink")) {
      onSelectProject(project);
    }
  };

  return (
    <div
      id={`project_list_item--${id}`}
      className={clsx(
        "mb-2 mt-2 flex flex-1 flex-col bg-white",
        clickable && "cursor-pointer"
      )}
      onClick={(e) => handleClick(e)}
    >
      <div className="space-between flex w-full px-2">
        <div className="mr-1 mt-1 w-1/4 flex-2 items-center truncate">
          <Header Icon={UilBriefcase} text={$.project_label} />
          <div className="text-ellipsis text-sm font-bold text-midnight">
            <Link
              id={`projectLink--${project_id}`}
              className="inline-block text-ellipsis text-sm text-midnight"
              to={`/admin/projects/${project_id}`}
            >
              {project_name}
            </Link>
          </div>
        </div>
        <div className="mt-1 flex-1">
          <Header Icon={UilFile} text={$.status_label} />
          <div className="text-ellipsis text-sm font-bold text-midnight">
            {statusOptions.find((o) => o.value === status)?.label ||
              $.unknown_value}
          </div>
        </div>
        <div className="mt-1 flex-1">
          <Header Icon={UilUserCircle} text={$.created_by_label} />
          <div className="text-ellipsis text-sm font-bold text-midnight">
            {created_by_name || $.unknown_value}
          </div>
        </div>
        <div className="mt-1 flex-1">
          <Header Icon={UilHeartRate} text={$.project_stage_label} />
          <div className="text-ellipsis text-sm font-bold text-midnight">
            {stageOptions.find((o) => o.value === project_stage)?.label ||
              $.unknown_value}
          </div>
        </div>
        <div className="mt-1 flex-1">
          <Header Icon={UilCalendarAlt} text={$.project_updated_label} />
          <div className="text-ellipsis text-sm font-bold text-midnight">
            {project_last_updated
              ? toFormat(new Date(project_last_updated), "MMMM dd, yyyy")
              : $.unknown_value}
          </div>
        </div>
        <div className="mt-1 flex-1">
          <Header Icon={UilCalendar} text={$.added_to_project_label} />
          <div className="text-ellipsis text-sm font-bold text-midnight">
            {created_at
              ? toFormat(new Date(created_at), "MMMM dd, yyyy")
              : $.unknown_value}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectListItem;
